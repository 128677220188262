import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import styles from "./DownloadMediaScreen.module.css";

const DownloadMediaScreen = ({ qrCodeData }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const { hyperlinks } = qrCodeData;
  const mediaDescription = currentLocale.includes("en") ? hyperlinks[0].DESCRIPTION_EN : hyperlinks[0].DESCRIPTION_FR;

  return (
    <div className='bg_screen'>
      <div className='centered-element'>
        <div className='col-12 logo_qrcode'>
          <p className={`text-start roomname ${styles.qrtitle}`}>{mediaDescription}</p>
          <img
            src={`/media_content/Loupe-QR.png`}
            alt="imgEmptyQRCode"
            className={styles.qrlogo}
          />
        </div>
      </div>
    </div>
  );
};

DownloadMediaScreen.propTypes = {
  qrCodeData: PropTypes.object.isRequired,
};

export default DownloadMediaScreen;
