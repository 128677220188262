import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AppPilot from "./AppPilot";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import SelectScreenReducer from "./reducers/SelectScreenReducer";
import EquipmentReducer from "./reducers/EquipmentReducer";
import TypeReducer from "./reducers/TypeReducer";
import CodeQRReducer from "./reducers/CodeQRReducer";
import NoIncidentReducer from "./reducers/NoIncidentReducer";
import SectionNoReducer from "./reducers/SectionNoReducer";
import { createRoot } from "react-dom/client";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
} from "chart.js";
const rootReducer = combineReducers({
  selectScreen: SelectScreenReducer,
  stateEquipment: EquipmentReducer,
  CodeQr: CodeQRReducer,
  stateType: TypeReducer,
  stateNoIncident: NoIncidentReducer,
  stateSectionNo: SectionNoReducer,
});
const store = createStore(rootReducer);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function getUrlVars() {
  let entry;
  let AppType = window.location.href.indexOf("admin");
  let AppRoute = window.location.href.indexOf("protected");

  if (AppType !== -1 || AppRoute !== -1) {
    entry = "admin";
    return entry;
  } else {
    entry = "toto";
    return entry;
  }
}
let container, root;
let urlParams = getUrlVars();

switch (urlParams) {
  case "admin":
    container = document.getElementById("root");
    root = createRoot(container);
    root.render(
      <Provider store={store}>
        <BrowserRouter>
          <AppPilot />
        </BrowserRouter>
      </Provider>
    );
    break;

  case undefined:
  default:
    container = document.getElementById("root");
    root = createRoot(container);
    root.render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    );
    break;
}
//serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
