import Amplify, { Auth } from "aws-amplify";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@nbc-design/button";
import { withTranslation } from "react-i18next";
import State from "./components/utils/state/State";
import { Line } from "react-chartjs-2";
import AWS from "aws-sdk";
import aws4 from "aws4";
import axios from "axios";
import { Card } from "@nbc-design/card";
import { Icon } from "@nbc-design/icons";
import StatusCard from "./components/pilotage/StatusCard";
import colors from "../src/components/utils/colors.json";
const hoststring = process.env.REACT_APP_HOST_STRING;
const urldest = process.env.REACT_APP_URL_DEST;
const pathHealth = process.env.REACT_APP_PATH_HEALTH;
const offset = new Date().getTimezoneOffset();
const usedConfig = {
  region: process.env.REACT_APP_CO_REGION,
  identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
};

AWS.config.region = usedConfig.region;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: usedConfig.identityPoolId,
});
const oauth = {
  domain: process.env.REACT_APP_CO_DOMAIN,
  redirectSignIn: process.env.REACT_APP_CO_DOMAIN_RE_SIGNIN,
  redirectSignOut: process.env.REACT_APP_CO_DOMAIN_RE_SIGNOUT,
  responseType: "token",
  scope: ["openid"],
};

const config = Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    oauth,
    region: process.env.REACT_APP_CO_REGION,
    userPoolId: process.env.REACT_APP_CO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CO_USER_POOL_WEB_CLIENT,
  },
});
const { domain, redirectSignIn, responseType } = config.Auth.oauth;
let url = "";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    let today = new Date(new Date().getTime() - offset * 60 * 1000)
      .toISOString()
      .replace(/.\d+Z$/g, "");

    let date = new Date();
    date.setDate(date.getDate() - 7);
    date.setTime(date.getTime() - offset * 60 * 1000);
    let lastWeek = date.toISOString().replace(/.\d+Z$/g, "");

    this.state = {
      authenticated: false,
      showCurrentStatus: false,
      disableRefresh: false,
      isAuthenticating: true,
      currentStatus: {},
      data: [],
      dates: {
        startDate: lastWeek,
        endDate: today,
      },
      datasets: [
        {
          label: "Front-End",
          data: [],
          borderColor: "rgb(26, 110, 144)",
          backgroundColor: "rgba(26, 110, 244, 0.5)",
        },
        {
          label: "Back-End",
          data: [],
          borderColor: "rgb(	34, 145, 229)",
          backgroundColor: "rgba(	34, 145, 229, 0.5)",
        },
      ],
      labels: [],
      options: {
        responsive: true,
        scales: {
          y: {
            max: 1,
            min: 0,
            ticks: {
              stepSize: 1,
            },
          },
        },
      },
    };
  }

  async componentWillMount() {
    setTimeout(async () => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          const nextState = {
            authenticated: true,
            isAuthenticating: false,
          };
          localStorage.removeItem("EquipData");
          localStorage.removeItem("TypeData");
          this.setState(nextState);
        }
      } catch (e) {
        this.constructUrl();
        const nextState = {
          authenticated: false,
          isAuthenticating: false,
        };
        this.setState(nextState);
      }
    }, 500);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.authenticated !== prevState.authenticated) {
      await this.getHealthCheck(this.state.dates);
    }
    if (
      this.state.data !== prevState.data &&
      this.state.data !== undefined &&
      this.state.data.length > 0
    )
      this.calculateDatesAndLabels();
  }
  getHealthCheck = async ({ startDate, endDate }) => {
    const credentials = AWS.config.credentials;

    let timeStampStart = Date.parse(startDate) / 1000;
    let timeStampEnd = Date.parse(endDate) / 1000;
    let params = `?start=${timeStampStart}&end=${timeStampEnd}`;
    if (startDate === "" || endDate === "" || startDate > endDate) {
      timeStampStart = "";
      timeStampEnd = "";
      params = "";
    }

    await credentials.get(async (err) => {
      if (err) {
        //Handle error
      } else {
        let opts = {
          host: hoststring,
          method: "GET",
          url: urldest + pathHealth + "report/" + params,
          path: pathHealth + "report/" + params,
          // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
        };

        let signedRequest = aws4.sign(opts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete signedRequest.headers["Host"];
        delete signedRequest.headers["Content-Length"];

        this.handleAWSResponse("data", (await axios(signedRequest)).data);
      }
    });
  };
  getHealthCheckRefresh = async () => {
    const credentials = AWS.config.credentials;

    await credentials.get(async (err) => {
      if (err) {
        //Handle error
      } else {
        let opts = {
          host: hoststring,
          method: "GET",
          url: urldest + pathHealth + "refresh/",
          path: pathHealth + "refresh/",
          // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
        };

        let signedRequest = aws4.sign(opts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete signedRequest.headers["Host"];
        delete signedRequest.headers["Content-Length"];
        let response = await axios(signedRequest);
        this.setState({
          currentStatus: response.data,
          showCurrentStatus: true,
        });
        //this.handleAWSResponse("data", (await axios(signedRequest)).data);
      }
    });
  };
  calculateDatesAndLabels = () => {
    let labels = [];
    let apps = [];

    this.state.data.forEach((day) => {
      let date = new Date(Number(day.id) * 1000);
      date.setTime(date.getTime() - offset * 60 * 1000);
      labels.push(
        date
          .toISOString()
          .replace(/.\d+Z$/g, "")
          .split("T")[0] +
          " " +
          date
            .toISOString()
            .replace(/.\d+Z$/g, "")
            .split("T")[1]
      );
      const keys = Object.keys(day);
      keys.forEach((key) => {
        if (key.endsWith("status")) {
          let serviceName = key.split("_")[0];
          if (apps.filter((x) => x.label === serviceName).length == 0) {
            let randomNumber;
            let color;
            do {
              randomNumber = Math.floor(Math.random() * (279 + 1)) + 1;
              color = colors[randomNumber];
            } while (apps.filter((s) => s.borderColor === color).length === 1);
            apps.push({
              data: [],
              label: serviceName,
              borderColor: color,
              backgroundColor: "#FFFFFF",
            });
          }
          let service = apps.filter((s) => s.label === serviceName)[0];
          service.data.push(day[key] === "200" ? 1 : 0);
        }
      });
    });

    this.setState({ datasets: apps, labels });
  };
  handleAWSResponse = (key, data) => {
    this.setState({ [key]: data });
  };
  handleRefresh = async () => {
    this.setState({ disableRefresh: true }, async () => {
      // Call API
      let response = await this.getHealthCheckRefresh();
      const refreshTimeout = setTimeout(() => {
        this.setState({ disableRefresh: false }, () => {
          clearTimeout(refreshTimeout);
        });
      }, 60000);
    });
  };
  handleFilterChange = (event) => {
    if (event.target.name === "startDate")
      this.setState(
        {
          dates: { ...this.state.dates, startDate: event.target.value },
        },
        async () => {
          await this.getHealthCheck(this.state.dates);
        }
      );
    if (event.target.name === "endDate")
      this.setState(
        {
          dates: { ...this.state.dates, endDate: event.target.value },
        },
        async () => {
          await this.getHealthCheck(this.state.dates);
        }
      );
  };
  render() {
    const { authenticated, isAuthenticating } = this.state;
    const { t } = this.props;

    if (authenticated) {
      // Recuperer le userId

      return (
        <div className='bg_screen'>
          <div className='centered-element'>
            <div>
              <State roomname={"Dashboard"} dates={this.state.dates} />
            </div>
            <div>
              <input
                type='datetime-local'
                value={this.state.dates.startDate}
                onChange={this.handleFilterChange}
                className='custom-datetime-picker'
                name={"startDate"}
                step='1'
              />{" "}
              <input
                type='datetime-local'
                value={this.state.dates.endDate}
                onChange={this.handleFilterChange}
                className='custom-datetime-picker'
                name={"endDate"}
                step='1'
                style={{ marginRight: "5rem" }}
              />{" "}
              <Button
                key='accueil'
                className='btntext is-padding'
                appearance='primary'
                fluid
                disabled={this.state.disableRefresh}
                size='small'
                style={{ width: 25, height: 25 }}
                onClick={() => this.handleRefresh()}
                children={<Icon name='refresh' title='refresh' />}
              />{" "}
              {this.state.data !== undefined && this.state.data.length > 0 && (
                <Line
                  options={this.state.options}
                  data={{
                    labels: this.state.labels,
                    datasets: this.state.datasets,
                  }}
                  height={120}
                  style={{ marginBottom: "2rem" }}
                />
              )}
              {this.state.showCurrentStatus && (
                <StatusCard currentStatus={this.state.currentStatus} />
              )}
            </div>
            <div className='w-100' style={{ marginTop: "6rem" }}>
              <NavLink to={"/protected/ManageQrCode"}>
                <Button
                  key='accueil'
                  className='btntext is-padding'
                  appearance='primary'
                  fluid
                  size='large'
                  children={t("ManageQrCodes.translated-text")}
                />
              </NavLink>
            </div>
          </div>
        </div>
      );
    }

    if (isAuthenticating) {
      return null;
    }

    window.location.assign(url);
    return null;
  }

  constructUrl() {
    const clientId = config.Auth.userPoolWebClientId;
    url =
      "https://" +
      domain +
      "/login?redirect_uri=" +
      redirectSignIn +
      "&response_type=" +
      responseType +
      "&client_id=" +
      clientId;
  }
}
export default withTranslation()(PrivateRoute);
