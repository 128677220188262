import logo from "../../bnc.svg";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./Header.module.css";

function Header(props) {
  const dispatch = useDispatch();

  //Reset breadcrumb
  const addScreenSelected = (screenValue) => {
    dispatch({
      type: "ADDSCREEN",
      payload: screenValue,
    });
  };
  const addEquipmentData = () => {
    dispatch({
      type: "ADDTAGEQUI",
      payload: "",
    });
  };

  const addTypeData = () => {
    dispatch({
      type: "ADDTAGTYPE",
      payload: "",
    });
  };

  function ResetFields() {
    localStorage.setItem("selectedEquip", JSON.stringify(""));
    localStorage.setItem("selectedType", JSON.stringify(""));
    localStorage.setItem("selectedRadio", JSON.stringify(""));
    localStorage.setItem("selectedHardware", JSON.stringify(""));
    localStorage.setItem("description", JSON.stringify(""));
    localStorage.setItem("courriel", JSON.stringify(""));
    localStorage.setItem("telNum", JSON.stringify(""));
    localStorage.setItem("showInfos", JSON.stringify(false));
    localStorage.setItem("EquipData", JSON.stringify(""));
    localStorage.setItem("EquipDataID", JSON.stringify(""));
    localStorage.setItem("TypeData", JSON.stringify(""));
    localStorage.setItem("NoSection", JSON.stringify(1));
    localStorage.setItem("SelectScreen", JSON.stringify("home"));
    localStorage.setItem("allEquipments", JSON.stringify([]));
    localStorage.removeItem("allTypesOfProblems");
    localStorage.removeItem("selectedCategory");
  }

  const headerContent = (
    <div>
      <div className='d-inline'>
        <img className={styles.imglogo} src={logo} alt='Logo' />
      </div>
      <div className='d-inline'>
        <span className={styles.headertitle}>Valet</span>
      </div>
    </div>
  );

  return (
    <header className={styles.header}>
      { props.codeQR ? (
        <Link
          to={`/?uid=${props.codeQR}`}
          data-testid="header-link"
          onClick={(e) => {
            ResetFields();

            addScreenSelected("home");
            addEquipmentData();
            addTypeData();
          }}
        >
          {headerContent}
        </Link>
      ) : headerContent }
    </header>
  );
}
export default Header;
