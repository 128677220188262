import React, { useEffect, useState } from "react";
import { Button } from "@nbc-design/button";
import { CardButton } from "@nbc-design/card";
import { Message } from "@nbc-design/message";
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import State from "../utils/state/State";
import ProgressLoading from "../utils/progressLoading/ProgressLoading";
import { ArrowLeft } from "@nbc-design/icons/lib/web/ArrowLeft";
import styles from "./Screen01.module.css";
import { useDispatch } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "axios";
import AWS from "aws-sdk";
import aws4 from "aws4";

const dropdownOptionsEquipment = [
  { name: "hardware", key: "monitor", value: "ecran" },
  { name: "hardware", key: "station", value: "Docking Station" },
  { name: "hardware", key: "cable", value: "Network cable" },
];

function Screen01(props) {
  localStorage.setItem("SelectScreen", JSON.stringify("/Screen01/form01"));

  const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });

  const hoststring = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathbmc = process.env.REACT_APP_PATH_BMC;
  const pathlog = process.env.REACT_APP_PATH_LOG;

  const [selectedhardware, setSelectedhardware] = useState("");
  const [DataisLoaded, setDataisLoaded] = useState(false);
  const [incidentInfos, setIncidentInfos] = useState([]);
  const [description2, setDescription2] = useState("");
  const [formSecNum, setFormSecNum] = useState(
    JSON.parse(localStorage.getItem("NoSection"))
  );
  const [transitionmode, setTransitionmode] = useState("fadeff");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const addHardwareData = (HardwareValue) => {
    dispatch({
      type: "ADDTAGEQUI",
      payload: HardwareValue,
    });
  };
  const addNoIncident = (NoIncident) => {
    dispatch({
      type: "ADDNOINC",
      payload: NoIncident,
    });
  };
  const addScreenSelected = (screenValue) => {
    dispatch({
      type: "ADDSCREEN",
      payload: screenValue,
    });
  };
  // This function logs a catch request using AWS4 to sign the request and axios to send the request.

  const logCatchRequest = (credential, codeQR, location, values, error) => {
    // Call the get method of the AWS credentials object to retrieve the security credentials.
    credential.get(function (err) {
      if (err) {
        // Handle error
      } else {
        const credentials = credential; // Get the credentials
        const { accessKeyId } = credentials;
        let hardwareLabel = ""; // Declare the hardware label variable and initialize to an empty string

        switch (
          values.hardware // Switch statement to set the hardware label based on the type of hardware
        ) {
          case "monitor": {
            // if the hardware is a monitor
            hardwareLabel = t("monitor.translated-text", { lng: "fr" }); // translate the text
            break;
          }

          case "station": {
            // if the hardware is a station
            hardwareLabel = t("station.translated-text", { lng: "fr" }); // translate the text
            break;
          }

          default: {
            // if the hardware is neither a monitor nor a station
            hardwareLabel = t("cable.translated-text", { lng: "fr" }); // translate the text
          }
        }

        // Object containing data to be sent in the request body
        let logdatabody = {
          type: "Applicatif",
          action: "Erreur",
          user: "system",
          sessionToken: accessKeyId,
          details:
            "Endpoint=" +
            urldest +
            pathlog +
            ";  Données passés =(QrCode= " +
            codeQR +
            ", Localisation : " +
            location +
            ", Équipement : " +
            hardwareLabel +
            ", Erreur : " +
            error,
        };

        // Object containing options for the request
        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        // Use AWS4 to sign the request
        let logsignedRequest = aws4.sign(logopts, {
          // Set the credentials for the request
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        // Some browsers don't allow setting these headers, so remove them to avoid errors/warnings in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];
        if (window.location.search.includes("&verbose=1")) {
          console.log(logdatabody, "verbose");
        }
        // Send the request using axios
        axios(logsignedRequest)
          .then(function (response) {
            //console.log(response);
          })
          .catch(function (error) {})
          .finally(() => {});
      }
    });
  };

  const logGetRequest = (
    codeQR,
    values,
    location,
    status,
    incidentNum,
    credential
  ) => {
    // Scanned QRCode logging
    credential.get(function (err) {
      if (err) {
        // Handle error
      } else {
        const credentials = credential;
        const { accessKeyId } = credentials;
        // Create the data to be logged
        let logdatabody = {
          type: "Applicatif",
          action: "Créer billet",
          user: "system",
          sessionToken: accessKeyId,
          details:
            "QrCode=" +
            codeQR +
            "; Appel=GET" +
            "; Matériel=" +
            values.hardware +
            "; Localisation=" +
            location +
            "; Status=" +
            status +
            "; No incident=" +
            incidentNum,
        };

        // Create the options for the log request
        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        // Sign the request with AWS credentials
        let logsignedRequest = aws4.sign(logopts, {
          // Assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        // Some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];

        // Send the log request
        axios(logsignedRequest)
          .then(function (response) {})
          .catch(function (error) {
            // Log the error response
            logCatchRequest(credentials, codeQR, location, values, error);
          })
          .finally(() => {});
      }
    });
  };

  // Function for logging scanned QRCode
  const logPostRequest = (
    codeQR,
    values,
    location,
    status,
    incidentNum,
    credential
  ) => {
    // Retrieve AWS credentials using get function
    credential.get(function (err) {
      if (err) {
        // Handle error
      } else {
        const credentials = credential;
        const { accessKeyId } = credentials;
        // Create object containing data from QR code
        let logdatabody = {
          type: "Applicatif",
          action: "Créer billet",
          user: "system",
          sessionToken: accessKeyId,
          details:
            "QrCode=" +
            codeQR +
            "; Appel=POST" +
            "; Matériel=" +
            values.hardware +
            "; Localisation=" +
            location +
            "; Status=" +
            status +
            "; No incident=" +
            incidentNum,
        };

        // Set options for the request
        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        // Sign the request using AWS credentials
        let logsignedRequest = aws4.sign(logopts, {
          // Assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        // Remove some headers that some browsers don't allow
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];

        // Send the request using axios library
        axios(logsignedRequest)
          .then(function (response) {})
          .catch(function (error) {
            // Call logCatchRequest function in case of error
            logCatchRequest(credentials, codeQR, location, values, error);
          })
          .finally(() => {});
      }
    });
  };

  const submitFormRequest = (values, credential) => {
    // Scanned QRCode logging

    let template = "";
    let hardwareLabel = "";

    switch (values.hardware) {
      case "monitor": {
        template = "AGGCKU7UD5YDVAQ3HXA8Q2JWDFGKVO";
        hardwareLabel = t("monitor.translated-text", { lng: "fr" });
        break;
      }

      case "station": {
        template = "AGGCKU7UD5YDVAQ3EDCCQ2GCEQZ6IE";
        hardwareLabel = t("station.translated-text", { lng: "fr" });
        break;
      }
      default: {
        template = "AGGCKU7UD5YDVAQ3HXFPQ2JWI3GLCK";
        hardwareLabel = t("cable.translated-text", { lng: "fr" });
      }
    }
    const jsonData = `{
      "values" : {
         "TemplateID" : "${template}",
         "Login_ID" : "QRCode",
         "Description" : "Équipement non personnalisé - ${hardwareLabel} ${props.roomname}",
         "Detailed_Decription" : "Probleme avec ${hardwareLabel} dans le bureau EDT ${props.roomname}. \\n${values.desDetPbForm}"
      }
   }`;
    const query_params = [
      `'Description' = "Équipement non personnalisé - ${hardwareLabel} ${props.roomname}"`,
      `'Status' = "Assigned" OR`,
      `'Status' = "In Progress" OR`,
      `'Status' = "Pending"`,
    ].join(" ");
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;

        //get the data from a QR code
        let databody = {
          query_params: query_params,
          jsonData: jsonData,
        };

        let opts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathbmc,
          path: pathbmc,
          data: { query_params: query_params, jsonData: jsonData },
          body: JSON.stringify(databody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let signedRequest = aws4.sign(opts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete signedRequest.headers["Host"];
        delete signedRequest.headers["Content-Length"];
        axios(signedRequest)
          .then((response) => {
            if (response.status >= 400) {
              throw new Error(`Response error ${response.status}`);
            }
            setIncidentInfos(response.data);
            setDataisLoaded(true);
          })
          .catch((error) => {
            console.error(error);
            logCatchRequest(
              AWS.config.credentials,
              props.codeQR,
              props.roomname,
              formValues,
              error
            );
            navigate("/ErrorMsg");
          });
      }
    });
  };

  const initialvalues = {
    hardware: "",
    desDetPbForm: "",
  };
  const [formValues, setFormValues] = useState(initialvalues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const normalizedValue = value
      .split("\n")
      .join(" ")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    localStorage.setItem(
      "formValues",
      JSON.stringify({ ...formValues, [name]: normalizedValue })
    );
  };

  const handleChangehardware = (event) => {
    localStorage.setItem(
      "selectedHardware",
      JSON.stringify(event.target.value)
    );
  };
  useEffect(() => {
    setFormValues(JSON.parse(localStorage.getItem("formValues")));
    setSelectedhardware(JSON.parse(localStorage.getItem("selectedHardware")));
    setDescription2(JSON.parse(localStorage.getItem("description2")));
  }, []);
  const handleChangeNavButton = (e) => {
    setTransitionmode(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      submitFormRequest(formValues, AWS.config.credentials);
    }

    ResetFeilds();
  };

  function ResetFeilds() {
    localStorage.setItem("selectedHardware", JSON.stringify(""));
    localStorage.setItem("description2", JSON.stringify(""));
  }

  const validate = (values) => {
    const errors = {};

    if (!values.hardware) {
      errors.hardware = (
        <Message appearance='error'>
          {t("validatehardware.translated-text")}
        </Message>
      );
    }

    return errors;
  };
  const nextPage = () => {
    let page;
    switch (formSecNum) {
      case 1: {
        if (formValues.hardware == "") {
        } else {
          setFormSecNum(2);
          setFormErrors({});
          localStorage.setItem("NoSection", JSON.stringify(2));
          page = navigate(`/Screen01/form02/?uid=${props.codeQR}`);
        }

        break;
      }

      default: {
        setFormSecNum(3);
        localStorage.setItem("NoSection", JSON.stringify(3));
      }
    }
    return page;
  };

  const prevPage = (secNum) => {
    let page;
    switch (secNum) {
      case 1: {
        addScreenSelected("home");
        page = navigate("/");

        break;
      }

      default: {
        page = navigate(`/Screen01/form01/?uid=${props.codeQR}`);
        setFormErrors({});
        setFormSecNum(1);
        localStorage.setItem("NoSection", JSON.stringify(1));

        break;
      }
    }
    return page;
  };

  useEffect(() => {
    if (DataisLoaded && incidentInfos.Success == "Ok") {
      logGetRequest(
        props.codeQR,
        formValues,
        props.roomname,
        incidentInfos.Status,
        incidentInfos.Ticket,
        AWS.config.credentials
      );
      addNoIncident(incidentInfos.Ticket);
      if (incidentInfos.Mode == "Exist") {
        navigate(`/existsMsg/?uid=${props.codeQR}`);
      } else {
        logPostRequest(
          props.codeQR,
          formValues,
          props.roomname,
          incidentInfos.Status,
          incidentInfos.Ticket,
          AWS.config.credentials
        );

        navigate(`/successMsg/?uid=${props.codeQR}`);
      }
    } else {
      if (DataisLoaded) {
        logCatchRequest(
          AWS.config.credentials,
          props.codeQR,
          props.roomname,
          formValues
        );
        navigate(`/ErrorMsg/?uid=${props.codeQR}`);
      } else {
      }
    }
  }, [incidentInfos]);

  if (DataisLoaded) {
    return (
      <div>
        <ProgressLoading />
      </div>
    );
  } else {
    return (
      <div className='bg_screen'>
        <div className='centered-element'>
          <div>
            <State roomname={props.roomname} />
          </div>
          <div className='d-flex flex-column align-items-center  btnsection'>
            <form
              onSubmit={handleSubmit}
              id='incidentForm'
              className='d-flex flex-row justify-content-center  bg_screen'
            >
              <div className='d-flex flex-row'>
                <TransitionGroup component={null}>
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames={transitionmode}
                    unmountOnExit
                  >
                    <Routes location={location}>
                      <Route
                        path='form01'
                        element={
                          <div id='hardware' className='contentbutton'>
                            <div className='d-flex flex-column justify-content-start'>
                              <label className='formselect'>
                                {t("equipmentpblabel.translated-text")}
                              </label>
                              <div>
                                {dropdownOptionsEquipment.map((option) => {
                                  return (
                                    <div className=' w-100'>
                                      <CardButton
                                        className='spc-card-button'
                                        name={option.name}
                                        id={option.value}
                                        checked={
                                          selectedhardware === option.key
                                        }
                                        onChange={(e) => {
                                          handleChange(e);
                                          handleChangehardware(e);
                                          setSelectedhardware(
                                            JSON.parse(
                                              localStorage.getItem(
                                                "selectedHardware"
                                              )
                                            )
                                          );
                                          setFormValues(
                                            JSON.parse(
                                              localStorage.getItem("formValues")
                                            )
                                          );
                                        }}
                                        onClick={(e) => {
                                          addHardwareData(option.key);
                                          localStorage.setItem(
                                            "EquipData",
                                            JSON.stringify(option.key)
                                          );
                                        }}
                                        value={option.key}
                                        role='radio'
                                        title={t(
                                          `${option.key}.translated-text`
                                        )}
                                      />
                                    </div>
                                  );
                                })}
                                <p>{formErrors.hardware}</p>
                              </div>
                            </div>
                          </div>
                        }
                      />
                      <Route
                        path='form02'
                        element={
                          <div id='DesDetPbForm' className=' contentbutton'>
                            <div className='form-group formselectsingle d-flex flex-column align-items-start'>
                              <label
                                className={`formselect ${styles.textarealabel}`}
                              >
                                {t("dtldescPbopt.translated-text")}
                              </label>
                              <textarea
                                name='desDetPbForm'
                                value={formValues.desDetPbForm}
                                rows='5'
                                placeholder={t(
                                  "dtldescPbPholder.translated-text"
                                )}
                                className='form-control'
                                onChange={(e) => {
                                  handleChange(e);
                                  setFormValues(
                                    JSON.parse(
                                      localStorage.getItem("formValues")
                                    )
                                  );
                                  setDescription2(
                                    JSON.parse(
                                      localStorage.getItem("description2")
                                    )
                                  );
                                }}
                              />
                            </div>
                            <p>{formErrors.desDetPbForm}</p>
                          </div>
                        }
                      />
                    </Routes>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </form>
          </div>
        </div>
        <div
          className={`p-4 w-100 d-flex flex-row align-items-start ${styles.footer}`}
        >
          <div className='align-items-start'>
            <div className={`justify-content-center ${styles.imgcolorsmall}`}>
              <div>
                <ArrowLeft
                  size='small'
                  title='arrow-left'
                  onMouseOver={(e) => {
                    handleChangeNavButton("faderev");
                  }}
                  onClick={(e) => {
                    prevPage(formSecNum);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='w-75'></div>
          <div className='w-100 d-flex flex-row align-items-end'>
            <div className='w-100'>
              {formSecNum == 3 ? (
                <Button
                  type='submit'
                  form='incidentForm'
                  onClick={(e) => {
                    setFormErrors(validate(formValues));
                    e.currentTarget.blur();
                  }}
                  appearance='primary'
                  fluid
                  children={t("btnformsubmit.translated-text")}
                />
              ) : (
                <Button
                  className='dsc-btn-cust'
                  type='input'
                  appearance='primary'
                  fluid
                  onMouseOver={(e) => {
                    handleChangeNavButton("fadeff");
                  }}
                  onClick={(e) => {
                    setFormErrors(validate(formValues));
                    nextPage();
                    e.currentTarget.blur();
                  }}
                  children={
                    formSecNum == 2
                      ? t("btnformsubmit.translated-text")
                      : t("btnformnext.translated-text")
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Screen01;
