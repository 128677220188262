//import './App.css';
import {
  Header,
  Screen01,
  Screen02,
  Screen03,
  Screen04,
  DownloadMediaScreen,
  SuccessMsg,
  AwsErrorMsg,
  ExistsMsg,
  ErrorMsg,
  EmptyMsg,
  ProgressLoading,
} from "./components";
import React, { useState, useEffect } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import AWS from "aws-sdk";
import aws4 from "aws4";
import NotFoundMsg from "./components/screenMsg/errorMsg/NotFoundMsg";
import i18n from "i18next";
import { useLocation } from "react-router-dom";
export const logCatchRequest = (credential, codeQR, error, path) => {
  const hoststring = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathlog = process.env.REACT_APP_PATH_LOG;
  credential.get(function (err) {
    if (err) {
      //Handle error
    } else if (error) {
      const credentials = credential;
      const { accessKeyId } = credential;
      const errorLogMsg = error?.response?.data?.message || error?.response?.data;
      let logdatabody = {
        type: "Applicatif",
        action: "Erreur",
        user: "system",
        sessionToken: accessKeyId,
        details:
        "Endpoint=" + urldest + path +
        ";QrCode=" +
        codeQR +
        ", Erreur= " +
        errorLogMsg,
      };
      let logopts = {
        host: hoststring,
        method: "POST",
        url: urldest + pathlog,
        path: pathlog,
        data: logdatabody,
        body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
        service: "execute-api",
        region: process.env.REACT_APP_CO_REGION,
        headers: {
          "content-type": "application/json",
        },
      };

      let logsignedRequest = aws4.sign(logopts, {
        // assumes user has authenticated and we have called
        // AWS.config.credentials.get to retrieve keys and
        // session tokens
        secretAccessKey: credentials.secretAccessKey,
        accessKeyId: credentials.accessKeyId,
        sessionToken: credentials.sessionToken,
      });

      //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
      delete logsignedRequest.headers["Host"];
      delete logsignedRequest.headers["Content-Length"];

      axios(logsignedRequest)
        .then(function (response) {
          //console.log(response);
        })
        .catch(function (error) {})
        .finally(() => {
          // console.log("log error final");
        });
    }
  });
};
function App(props) {
  /*useEffect(() => {
    localStorage.setItem('EquipData', JSON.stringify(""));
    localStorage.setItem('TypeData', JSON.stringify(""));
    }
  , []);*/

  const { t } = useTranslation();
  const [qrCode, setqrCode] = useState([]);
  const [qrCodeInfos, setqrCodeInfos] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const [awsError, setAwsError] = useState(false);
  const [notFoundError, setNotFoundError] = useState({
    isError: false,
    error: {},
  });
  const dispatch = useDispatch();
  const addQrCode = (QrCode) => {
    dispatch({
      type: "ADDQRCODE",
      payload: QrCode,
    });
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const scannedQrCode = queryParams.get("uid") ? queryParams.get("uid") : qrCodeInfos.qrcode;
  const verboseParam = queryParams.get("verbose");
  addQrCode(scannedQrCode);

  // Initialiser les variables de la fonction avec les variables d'environnement
  const hoststring = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathqrcode = process.env.REACT_APP_PATH_QR_CODE;
  const pathlog = process.env.REACT_APP_PATH_LOG;
  const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });

  useEffect(() => {
    if (verboseParam === "1") {
      localStorage.setItem("verboseAdded", "true");
    }
    if (window.location.pathname === "/") localStorage.clear();
    AWS.config.credentials.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = AWS.config.credentials;

        let databody = {
          pathParameters: {
            scannedQrCode: scannedQrCode,
          },
        };

        let opts = {
          host: hoststring,
          method: "GET",
          url: urldest + pathqrcode + scannedQrCode,
          path: pathqrcode + scannedQrCode,
          // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
        };

        let signedRequest = aws4.sign(opts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete signedRequest.headers["Host"];
        delete signedRequest.headers["Content-Length"];

        axios(signedRequest)
          .then(function (response) {
            let isD2L = response.data.d2l;
            let link = "en-US";
            if (isD2L) {
              link =
              navigator.language.slice(0, 2) === "en"
                  ? response.data.hyperlinks[0].URL_EN
                  : response.data.hyperlinks[0].URL_FR;
              window.location.replace(link);
            }
            setqrCode(response.data);
            setqrCodeInfos(response.data);
            setIsMounted(true);
          })
          .catch(function (error) {
            if (error.response && error.response.status === 404)
              setNotFoundError({ isError: true, error });
            else setAwsError(true);
            logCatchRequest(credentials, scannedQrCode, error, pathqrcode + scannedQrCode);
          })
          .finally(() => {});
      }
    });
  }, []);

  useEffect(() => {
    if (isMounted) {
      AWS.config.credentials.get(function (err) {
        if (err) {
          //Handle error
        } else {
          const credentials = AWS.config.credentials;
          const qrInfos = qrCode[0];
          const { accessKeyId } = credentials;

          //Portion pour obtenir les données d'un code QR

          let logdatabody = {
            type: "Applicatif",
            action: "Accueil",
            user: "system",
            sessionToken: accessKeyId,
            details:
              "QrCode=" +
              scannedQrCode +
              "; QrCode type Id=" +
              qrCodeInfos.id +
              "; Status=" +
              "test" +
              " " +
              "; Localisation=" +
              qrCodeInfos.location,
          };
          let logopts = {
            host: hoststring,
            method: "POST",
            url: urldest + pathlog,
            path: pathlog,
            data: logdatabody,
            body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
            service: "execute-api",
            region: process.env.REACT_APP_CO_REGION,
            headers: {
              "content-type": "application/json",
            },
          };

          let logsignedRequest = aws4.sign(logopts, {
            // assumes user has authenticated and we have called
            // AWS.config.credentials.get to retrieve keys and
            // session tokens
            secretAccessKey: credentials.secretAccessKey,
            accessKeyId: credentials.accessKeyId,
            sessionToken: credentials.sessionToken,
          });

          //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
          delete logsignedRequest.headers["Host"];
          delete logsignedRequest.headers["Content-Length"];
          if (location.search.includes("&verbose=1")) {
            console.log(logdatabody, "verbose");
          }

          axios(logsignedRequest)
            .then(function (response) {})
            .catch(function (error) {
              logCatchRequest(credentials, scannedQrCode, error, pathlog);
            })
            .finally(() => {});
        }
      });
    }
  }, [isMounted]);

  useEffect(() => {
    if (qrCodeInfos.qrcode === undefined) {
      //AwsErrorRequest(AWS.config.credentials);
    }
  }, [isMounted]);

  useEffect(() => {
    // Clear the localStorage when not needed
    if (scannedQrCode == null || qrCodeInfos.qrcode === undefined) {
      localStorage.removeItem("verboseAdded");
    }
  }, [scannedQrCode, qrCodeInfos.qrcode]);

  if (isMounted) {
    if (qrCodeInfos.qrcode !== undefined) {
      const firstQrCode = qrCodeInfos.qrcode;
      if (scannedQrCode == null || qrCodeInfos.qrcode === undefined) {
        return (
          <div className='App bg_screen'>
            <EmptyMsg scannedQR={scannedQrCode} feedbackLink=' ' />
          </div>
        );
      } else {
        const roomname = qrCodeInfos.location;
        const tiketType = firstQrCode.id;
        const status = firstQrCode.length > 0 ? "active" : "inactive";
        const target = qrCodeInfos.target;
        const address = qrCodeInfos.address;
        const codeQR = qrCodeInfos.qrcode;
        const feedback_Link = qrCodeInfos.link_retroaction;
        const typeId = qrCodeInfos.type_id;
        // If page download a media, show another landing page
        const isMediaDownloadPage = typeId === 17;
        const hasHyperlinks =
          qrCodeInfos.hyperlinks !== null && qrCodeInfos.hyperlinks.length > 0;
        const startScreen = () => {
          if (status !== "active") {
            return (
              <EmptyMsg
                scannedQR={scannedQrCode}
                status={status}
                feedbackLink={feedback_Link}
              />
            );
          }
        
          if (isMediaDownloadPage) {
            return <DownloadMediaScreen qrCodeData={qrCodeInfos} />;
          }
        
          return (
            <Screen02
              codeQR={codeQR}
              categories={qrCodeInfos.cat_equipement || []}
              ticketType={tiketType}
              typeId={typeId}
              roomname={roomname}
              feedbackLink={feedback_Link}
              hasHyperLinks={hasHyperlinks}
              address={address}
              verbose={verboseParam === "1" ? "1" : undefined} // Add verbose param if needed
            />
          );
        };
          
        return (
          <div className='App bg_screen'>
            <Header codeQR={codeQR} />

            <Routes>
              <Route path='/' element={startScreen()} />
              <Route
                path='/Screen01/*'
                element={
                  <Screen01
                    codeQR={codeQR}
                    ticketType={tiketType}
                    roomname={roomname}
                    verbose={verboseParam === "1" ? "1" : undefined} // Add verbose param if needed
                  />
                }
              />
              <Route
                path='/Screen03/'
                element={
                  <Screen03
                    qrData={qrCodeInfos.hyperlinks}
                    codeQR={codeQR}
                    ticketType={tiketType}
                    roomname={roomname}
                    feedbackLink={feedback_Link}
                    address={address}
                    typeId={typeId}
                    verbose={verboseParam === "1" ? "1" : undefined} // Add verbose param if needed
                  />
                }
              />
              <Route
                path='/Screen04/*'
                //path="/Screen04/*/?uid="{qr_code}
                element={
                  <Screen04
                    codeQR={codeQR}
                    ticketType={tiketType}
                    roomname={roomname}
                    target={target}
                    typeId={typeId}
                    address={address}
                    verbose={verboseParam === "1" ? "1" : undefined} // Add verbose param if needed
                  />
                }
              />
              <Route
                path='/SuccessMsg/'
                element={
                  <SuccessMsg
                    feedbackLink={feedback_Link}
                    ticketType={tiketType}
                    roomname={roomname}
                  />
                }
              />

              <Route
                path='/ExistsMsg/'
                element={
                  <ExistsMsg
                    feedbackLink={feedback_Link}
                    ticketType={tiketType}
                    roomname={roomname}
                  />
                }
              />
              <Route
                path='/ErrorMsg'
                element={
                  <ErrorMsg
                    feedbackLink={feedback_Link}
                    ticketType={tiketType}
                    roomname={roomname}
                  />
                }
              />
            </Routes>
          </div>
        );
      }
    } else {
      return (
        <div className='bg_white'>
          <Header />
          <div className='m-4'>
            <AwsErrorMsg />
          </div>
        </div>
      );
    }
  } else {
    if (notFoundError.isError) {
      return (
        <div className='bg_white'>
          <Header />
          <div className='m-4'>
            <NotFoundMsg error={notFoundError} />
          </div>
        </div>
      );
    } else if (awsError) {
      return (
        <div className='bg_white'>
          <Header />
          <div className='m-4'>
            <AwsErrorMsg />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <ProgressLoading />
          </div>
        </div>
      );
    }
  }
}

export default App;
