import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Confirmation } from "@nbc-design/confirmation";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@nbc-design/text";
import { NavLink } from "react-router-dom";
import { Button } from "@nbc-design/button";
import styles from "./SuccessMsg.module.css";
import { ExternalLink } from "@nbc-design/icons/lib/web/ExternalLink";
import axios from "axios";

import AWS from "aws-sdk";
import aws4 from "aws4";
import apiConfig from "../../../apiConfig.json";

function ScreenMsg(props) {
  const usedConfig = {
    region: apiConfig.cognito.region,
    identityPoolId: apiConfig.cognito.identityPoolId,
  };

  /*const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };*/

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });
  const hoststring = apiConfig.hoststring;
  const urldest = apiConfig.urldest;
  const pathlog = apiConfig.pathlog;

  /* const hoststringqrCode = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathlog = process.env.REACT_APP_PATH_LOG;
 */

  const logRetroRequest = (credential, QrCode, location) => {
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;

        //Portion pour obtenir les données d'un code QR

        let logdatabody = {
          type: "Applicatif",
          action: "Rétroaction",
          user: "system",
          details: "QrCode=" + QrCode + "; Localisation=" + location,
        };

        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let logsignedRequest = aws4.sign(logopts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];

        axios(logsignedRequest)
          .then(function (response) { })
          .catch(function (error) { })
          .finally(() => { });
      }
    });
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ticketmode = (ticketTypevalue) => {
    return ticketTypevalue != 1 ? "/Screen04/equipment" : "/Screen01/form01";
  };
  //const stateNoIncident = useSelector((state) => state.stateNoIncident);
  //const stateEquipment = useSelector((state) => state.stateEquipment);
  const stateEquipment = JSON.parse(localStorage.getItem("EquipData"));
  const stateType = JSON.parse(localStorage.getItem("TypeData"));
  const QrCode = useSelector((state) => state.CodeQr);
  //const stateType = useSelector((state) => state.stateType);
  const { stateNoIncident } = useSelector(({ stateNoIncident }) => ({
    stateNoIncident: stateNoIncident.stateNoIncident,
  }));

  function ResetFeilds() {
    localStorage.setItem("selectedEquip", JSON.stringify(""));
    localStorage.setItem("selectedType", JSON.stringify(""));
    localStorage.setItem("selectedRadio", JSON.stringify(""));
    localStorage.setItem("selectedHardware", JSON.stringify(""));
    localStorage.setItem("description", JSON.stringify(""));
    localStorage.setItem("courriel", JSON.stringify(""));
    localStorage.setItem("telNum", JSON.stringify(""));
    localStorage.setItem("showInfos", JSON.stringify(false));
    localStorage.setItem("EquipData", JSON.stringify(""));
    localStorage.setItem("EquipDataID", JSON.stringify(""));
    localStorage.setItem("TypeData", JSON.stringify(""));
    localStorage.setItem("NoSection", JSON.stringify(1));
    localStorage.setItem("SelectScreen", JSON.stringify("home"));
  }

  //Reset breadcrumb
  const addScreenSelected = (screenValue) => {
    dispatch({
      type: "ADDSCREEN",
      payload: screenValue,
    });
  };
  const addEquipmentData = () => {
    dispatch({
      type: "ADDTAGEQUI",
      payload: "",
    });
  };
  const addTypeData = () => {
    dispatch({
      type: "ADDTAGTYPE",
      payload: "",
    });
  };

  const addNoIncident = () => {
    dispatch({
      type: "ADDNOINC",
      payload: "",
    });
  };

  return (
    <div className='bg_white'>
      <div className='p-4'>
        <Confirmation
          title={t("requestsent.translated-text")}
          appearance='success'
        >
          <Text size='normal'>
            {t("successmsg.translated-text")}
            {stateNoIncident && `${t("NumIncident.translated-text")} ${stateNoIncident}`}
          </Text>
        </Confirmation>
      </div>
      <div className='middlesectionMsg'>
        <div className='container middlesectionMsg'>
          <div
            className={`d-flex flex-column  align-items-start ${styles.displaygroup}`}
          >
            <span className={styles.titlegroup}>
              {t("location.translated-text")}
            </span>
            <span className={styles.itemgroup}>{props.roomname}</span>
          </div>

          <div
            className={`pt-3 d-flex flex-column  align-items-start ${styles.displaygroup}`}
          >
            <span className={styles.titlegroup}>
              {t("equipmentlabel.translated-text")}
            </span>
            <span className={styles.itemgroup}>{t(`${stateEquipment}`)}</span>
          </div>
          {stateType == "" ? null : (
            <div
              className={`pt-3 d-flex flex-column  align-items-start ${styles.displaygroup}`}
            >
              <span className={styles.titlegroup}>
                {t("typeequipmentlabel.translated-text")}
              </span>
              <span className={styles.itemgroup}>{t(`${stateType}`)}</span>
            </div>
          )}
        </div>
        <div>
          <div className='p-4 d-flex flex-row align-items-start'></div>
          <div className='p-1 d-flex justify-content-center'>
            <div className='d-flex flex-row aling-items-center'>
              <ExternalLink size='small' className={styles.imgcolorsmall} />
              <a
                className={styles.linktext}
                onClick={() => {
                  logRetroRequest(
                    AWS.config.credentials,
                    QrCode.CodeQr,
                    props.roomname
                  );
                }}
                target='_blank'
                href={props.feedbackLink}
              >
                {t("feedback.translated-text")}
              </a>
            </div>
          </div>
          <div className='pt-2  d-flex justify-content-center'>
            <NavLink to={`/?uid=${QrCode.CodeQr}`} className={styles.navbutton}>
              <Button
                onClick={() => {
                  addScreenSelected("home");
                  addEquipmentData();
                  addTypeData();
                  addNoIncident();
                  ResetFeilds();
                }}
                appearance='primary'
                fluid
                size='large'
                children={t("btnbacktohome.translated-text")}
              />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreenMsg;
