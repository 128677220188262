import React, { Component } from "react";
import "./CardList.css";

class StatusCard extends Component {
  constructor(props) {
    super(props);
    this.state = { date: {}, showStatus: false, services: [] };
  }
  setStatus = (currentStatus) => {
    let date = new Date(Number(currentStatus.id) * 1000);
    let services = [];
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    let keys = Object.keys(currentStatus);
    keys.forEach((key) => {
      if (key.endsWith("status")) {
        let serviceName = key.split("_")[0];
        if (services.filter((x) => x.label === serviceName).length == 0) {
          services.push({
            label: serviceName,
            value: 0,
          });
        }
        let service = services.filter((s) => s.label === serviceName)[0];
        service.value = currentStatus[key] === 200;
      }
    });

    this.setState({ date, showStatus: true, services });
  };
  componentDidMount() {
    this.setStatus(this.props.currentStatus);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentStatus.id !== this.props.currentStatus.id)
      this.setStatus(this.props.currentStatus);
  }
  render() {
    return (
      <div className='cardContent'>
        <div className='cardContent-content'>
          <div className='date-range'>
            <p style={{ color: "#1572c5", fontSize: "16px" }}>
              {this.state.showStatus &&
                this.state.date
                  .toISOString()
                  .replace(/.\d+Z$/g, "")
                  .split("T")[0] +
                  " " +
                  this.state.date
                    .toISOString()
                    .replace(/.\d+Z$/g, "")
                    .split("T")[1]}
            </p>
          </div>
          <ul>
            {this.state.showStatus &&
              this.state.services.map((service) => (
                <li key={service.label}>
                  <span style={{ color: "#1572c5", fontSize: "16px" }}>
                    {service.label}
                  </span>{" "}
                  :{" "}
                  <span
                    style={{
                      color: service.value ? "green" : "red",
                      fontSize: "16px",
                    }}
                  >
                    {service.value ? "200" : "0"}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default StatusCard;
