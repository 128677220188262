import React from "react";

import styles from "./Screen02.module.css";
import { NavLink } from "react-router-dom";
import { Button } from "@nbc-design/button";
import { useTranslation } from "react-i18next";
import { ExternalLink } from "@nbc-design/icons/lib/web/ExternalLink";
import State from "../utils/state/State";
import axios from "axios";
import AWS from "aws-sdk";
import aws4 from "aws4";
import Categories from "./Categories";
import { logCatchRequest } from "../../App";

function Screen02(props) {
  const initialvalues1 = {
    hardware: "",
    desDetPbForm: "",
  };
  localStorage.setItem("formValues", JSON.stringify(initialvalues1));

  const initialvalues4 = {
    equipementpb: "",
    typepb: "",
    desDetPbForm: "",
    radioContact: "",
    adressCourrielForm: "",
    telNum: "",
    transitionmode: "slide-left",
  };
  localStorage.setItem("allValues", JSON.stringify(initialvalues4));
  localStorage.setItem("EquipData", JSON.stringify(""));
  localStorage.setItem("TypeData", JSON.stringify(""));

  localStorage.setItem("SelectScreen", JSON.stringify("home"));
  const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };

  const hoststring = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathlog = process.env.REACT_APP_PATH_LOG;

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });

  const logRetroRequest = (credential, QrCode, location) => {
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;
        const { accessKeyId } = credentials;
        let logdatabody = {
          type: "Applicatif",
          action: "Rétroaction",
          user: "system",
          sessionToken: accessKeyId,
          details: "QrCode=" + QrCode + "; Localisation=" + location,
        };

        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let logsignedRequest = aws4.sign(logopts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];
        if (window.location.search.includes("&verbose=1")) {
          console.log(logdatabody, "verbose");
        }

        axios(logsignedRequest)
          .then(function (response) {})
          .catch(function (error) {
            logCatchRequest(credentials, QrCode, error);
          })
          .finally(() => {});
      }
    });
  };
  const showHelpbutton = (ticketTypevalue) => {
    return props.hasHyperLinks ? (
      <div className='p-1 d-flex justify-content-center'>
        <NavLink
          to={`/Screen03/?uid=${props.codeQR}`}
          className={styles.navbutton}
        >
          <Button
            className='btntext is-padding'
            onClick={(e) => {
              localStorage.setItem("SelectScreen", JSON.stringify("/Screen03"));
            }}
            appearance='secondary'
            fluid
            size='large'
            children={t("taskHelp.translated-text")}
          />
        </NavLink>
      </div>
    ) : null;
  };

  const { t } = useTranslation();

  return (
    <div className='bg_screen'>
      <div className='centered-element'>
        
        <div>
          <State
            roomname={props.roomname}
            address={props.address}
            typeId={props.typeId}
          />
        </div>
        <div className='col-12 logo_qrcode'>
                <img 
                  src={`/media_content/logo_qrcodetype_${props.typeId}.png`}
                  style={{width:"100%",maxWidth:"150px",margin:"0 auto"}}
                />
        </div>
       
        <div className='middlesection'>
         <p class="text-start">{t(`createticket.translated-text`)}</p>
          <Categories
            categories={props.categories}
            typeId={props.typeId}
            codeQR={props.codeQR}
            verbose={props.verbose}
            style={{
              categoryText: styles.categorytext,
              arrowSection: styles.arrowsection,
            }}
          />
          {props.feedbackLink !== null && props.feedbackLink !== "" && (
            <div className='pt-4 d-flex justify-content-center'>
              <div className='d-flex flex-row aling-items-center'>
                <ExternalLink size='small' className={styles.imgcolorsmall} />
                <a
                  onClick={() => {
                    logRetroRequest(
                      AWS.config.credentials,
                      props.codeQR,
                      props.roomname
                    );
                  }}
                  className={styles.linktext}
                  target='_blank'
                  href={props.feedbackLink}
                >
                  {t("feedback.translated-text")}
                </a>
              </div>
            </div>
          )}
          <div className='pt-3'>{showHelpbutton(props.ticketType)}</div>
        </div>
      </div>
    </div>
  );
}
export default Screen02;
