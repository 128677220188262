import React, { useState } from "react";
import styles from "./EmptyMsg.module.css";
import { useTranslation } from "react-i18next";
import Header from "../../header/Header";
import { ExternalLink } from "@nbc-design/icons/lib/web/ExternalLink";
import axios from "axios";
import AWS from "aws-sdk";
import aws4 from "aws4";


//import { Transfer } from "@nbc-design/icons/lib/web/Transfer";

function EmptyMsg(props) {
  

  const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });



  const hoststring = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathlog = process.env.REACT_APP_PATH_LOG;
  const awsUrl = process.env.REACT_APP_AWS_URL;

  const LoupeQR = awsUrl + "media_content/Loupe-QR.png";

  const logRetroRequest = (credential, QrCode) => {
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;

        //Portion pour obtenir les données d'un code QR

        let logdatabody = {
          type: "Applicatif",
          action: "Rétroaction",
          user: "system",
          details: "QrCode=" + QrCode,
        };

        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: 'execute-api',
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let logsignedRequest = aws4.sign(logopts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];

        axios(logsignedRequest)
          .then(function (response) {})
          .catch(function (error) {
           // console.log("LOG Error Retroaction here!!!");
          //  console.log(error);
          })
          .finally(() => {});
      }
    });
  };
  const { t } = useTranslation();

  return (
    <div className="bg_screen">
      {props.status == "active" ? (
        <div>
          <Header />
        </div>
      ) : null}
      <div className="centered-element">
        <div>
          <img
            className={styles.picplaceolder}
            src={LoupeQR}
            alt="imgEmptyQRCode"
          />
        </div>
        {props.scannedQR == null ? (
          <div className="d-flex flex-column">
            <span className={styles.titletop}>
              {t("emptymsgtop.translated-text")}
            </span>
            <span className={styles.titlebottom}>
              {t("emptymsgbottom.translated-text")}
            </span>
          </div>
        ) : (
          <div>
            <div className="p-1 d-flex justify-content-center">
              <div className="d-flex flex-row aling-items-center">
                <ExternalLink size="small" className={styles.imgcolorsmall} />
                <a
                  className={styles.linktext}
                  onClick={(e) => {
                    logRetroRequest(AWS.config.credentials, props.scannedQR);
                  }}
                  target="_blank"
                  href={props.feedbackLink}
                >
                  {t("feedback.translated-text")}
                </a>
              </div>
            </div>
            <div className="d-flex flex-column">
              <span className={styles.titletop}>
                {t("notfoundmsgtop.translated-text")}
              </span>
              <span className={styles.titlebottom}>
                {t("notfoundmsgbottom.translated-text")}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default EmptyMsg;
