import React, { Component } from "react";
import { ProgressSpinner } from "@nbc-design/progress-spinner";

import styles from "./Spinner.module.css";

function ProgressLoading() {
  return (
    <div className={`centered-element bg_screen App ${styles.progressimg}`}>
      <div>
        <div>
          <ProgressSpinner size="xlarge" />
        </div>
      </div>
    </div>
  );
}
export default ProgressLoading;
