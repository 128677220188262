import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ManageQrCode.module.css";
import { Message } from "@nbc-design/message";
import { Button } from "@nbc-design/button";
import axios from "axios";
import AWS from "aws-sdk";
import aws4 from "aws4";
import { ProgressSpinner } from "@nbc-design/progress-spinner";
import xlsx from "xlsx";
import { useNavigate } from "react-router-dom";

function ManageQRCode(props) {
  const { t } = useTranslation();

  const fileRef = useRef();

  const hoststringqrCode = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathimportQrCode = process.env.REACT_APP_PATH_IMPORT_QR_CODE;
  const awsUrl = process.env.REACT_APP_AWS_URL;
  const pathFile = process.env.REACT_APP_PATH_FILES;
  const urlxlsxfile = process.env.REACT_APP_PATH_URL_DOWNLOAD_FILE;

  let fileSelect;

  const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });

  const [uploadFile, setUploadFile] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [buffresult, setBuffresult] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [totalFilesRows, setTotalFilesRows] = useState("0");
  const [filevalid, setFilevalid] = useState(true);
  const [userId, setUserId] = useState();
  const [msgError, setMsgError] = useState(false);
  const [timeout, setTimeout] = useState(false);
  const [overMaxNbRows, setOverMaxNbRows] = useState(false);
  const [maxNbRows, setMaxNbRows] = useState(false);
  const [uploadFileJson, setUploadFileJson] = useState({});
  const navigate = useNavigate();
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const [file] = e.target.files;
      setUploadFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        var range = xlsx.utils.decode_range(worksheet["!ref"]);
        range.s.c = 0;
        range.e.c = 4;
        const json = xlsx.utils.sheet_to_json(worksheet, {
          range: range,
          blankRows: false,
        });
        setUploadFileJson(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const sendFileRequest = (credential, xlsxjson, userId) => {
    AWS.config.credentials.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;

        const hoststring = hoststringqrCode;
        const urldestination = urldest;
        const pathImport = pathimportQrCode;
        const databody = {
          jsonData: xlsxjson,
          userId: userId,
        };

        const opts = {
          host: hoststring,
          method: "POST",
          url: urldestination + pathImport,
          path: pathImport,
          data: {
            jsonData: xlsxjson,
            userId: userId,
          },
          body: JSON.stringify(databody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let signedRequest = aws4.sign(opts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });
        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete signedRequest.headers["Host"];
        delete signedRequest.headers["Content-Length"];
        axios(signedRequest)
          .then(function (response) {
            setBuffresult(response.data.body);
            setTotalFilesRows(response.data.body[0].reject.totalRows);
            setMaxNbRows(response.data.body[0].reject.maxRow);
            setIsMounted(true);
          })
          .catch(function (error) {
            // console.log(error);
            setMsgError(true);

            setTimeout(true);
          })
          .finally(() => {});
      }
    });
  };

  useEffect(() => {
    const userEmail = props.token
      .then((response) => response)
      .then((data) => {
        setUserId(data);
      })
      .catch((error) => {
        if (error === "No current user") navigate("/admin");
      });
  }, []);
  //Send the file to the Lamda function for processing
  useEffect(() => {
    if (Object.keys(fileErrors).length === 0 && isSubmit) {
      sendFileRequest(AWS.config.credentials, uploadFileJson, userId);
      setFilevalid(true);
    }
  }, [fileErrors]);

  useEffect(() => {
    const nbIntractable = getIntractable();
    if (nbIntractable == totalFilesRows) {
      setMsgError(true);
    }

    if (totalFilesRows == 999999) {
      setOverMaxNbRows(true);
    }
  }, [isMounted]);

  // Calculer le nombre total de reject
  const getReject = () => {
    let nbcreate = 0;
    let nbupdate = 0;
    let totalReject = 0;
    buffresult.map((buffresult) => {
      if (buffresult.reject.create.qrCode.length != 0) {
        nbcreate = nbcreate + 1;
      }
      if (buffresult.reject.update.qrCode.length != 0) {
        nbupdate = nbupdate + 1;
      }
      totalReject = nbcreate + nbupdate;
    });
    return totalReject;
  };
  // Calculer le nombre de codes non traité
  const getIntractable = () => {
    let nbrows = 0;

    buffresult.map((buffresult) => {
      if (buffresult.reject.invalid.qrCode.length != 0) {
        nbrows = nbrows + 1;
      }
    });
    return nbrows;
  };

  // Calculer le nombre de code traiter
  const getTotalTreat = () => {
    let totalTreat = 0;
    totalTreat = totalFilesRows - (getIntractable() + getReject());
    return totalTreat;
  };

  //Validation of the file to be uploaded so that it meets the standards
  const validate = (uploadFile) => {
    const errors = {};

    if (!uploadFile.name) {
      errors.uploadFile = (
        <Message appearance='error'>
          {t("pilotvalidateupload.translated-text")}
        </Message>
      );
    } else if (
      uploadFile.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      errors.uploadFile = (
        <Message appearance='error'>
          {t("pilotvalidateformat.translated-text")}
        </Message>
      );
    } else if (uploadFile.name.indexOf("BNC_Valet_Pilotage") === -1) {
      errors.uploadFile = (
        <Message appearance='error'>
          {t("pilotvalidategabarit.translated-text")}
        </Message>
      );
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFileErrors(validate(uploadFile));
    setIsSubmit(true);
    setMsgError(false);
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    fileRef.current.click();
  };

  // message lors de fichier trop volumineux
  const msgOverMaxRows =
    t("pilotovermaxrowsfirst.translated-text") +
    maxNbRows +
    t("pilotovermaxrowssec.translated-text");

  return (
    <div className='centered-element'>
      <div className='bg_text d-flex flex-column justify-content-start'>
        <div className='roomname'>
          <span>{t("pilottitle.translated-text")}</span>
        </div>
      </div>
      <div></div>
      <div className='pb-3'>
        <div className={styles.btnsection}>
          <div className='d-flex flex-column w-100'>
            <span className={`admintitle ${styles.span}`}>
              {t("pilotstepone.translated-text")}
            </span>
            <a href={urlxlsxfile}>
              <Button
                type='file'
                className='btntext is-padding'
                //onClick={addScreenSelected(ticketmode(props.ticketType))}
                onClick={() => {
                  setFileErrors({});
                  setUploadFile({});
                  setFilevalid(false);
                  setIsMounted(false);
                  setIsSubmit(false);
                  setMsgError(false);
                  setTimeout(false);
                  setOverMaxNbRows(false);
                  setTotalFilesRows("0");
                }}
                appearance='primary'
                fluid
                size='large'
                children={t("pilotbtndownload.translated-text")}
              />{" "}
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className={`pt-2 d-flex flex-column w-100  ${styles.btnsection}`}>
          <div className='d-flex flex-column w-100 pt-3'>
            <span className={`admintitle ${styles.span}`}>
              {t("pilotsteptwo.translated-text")}
            </span>
            <Button
              type='file'
              className='btntext is-padding'
              //onClick={addScreenSelected(ticketmode(props.ticketType))}

              onClick={(e) => {
                onButtonClick(e);
                setFileErrors({});
                setUploadFile({});
                setIsSubmit(false);
                setFilevalid(false);
                setMsgError(false);
                setIsMounted(false);
                setTimeout(false);
                setOverMaxNbRows(false);
                setTotalFilesRows("0");
              }}
              appearance='primary'
              fluid
              size='large'
              children={t("pilotbtnselect.translated-text")}
            />
          </div>
          {fileErrors.uploadFile != null ? (
            <p className={styles.filenamesection}>{fileErrors.uploadFile}</p>
          ) : uploadFile != null ? (
            <p className={styles.filenamesection}>{uploadFile.name}</p>
          ) : null}
        </div>
      </div>
      <div>
        <div className={styles.btnsection}>
          <div className='d-flex flex-column w-100'>
            <span className={`admintitle ${styles.span}`}>
              {t("pilotstepthree.translated-text")}
            </span>
            <Button
              className='btntext is-padding'
              onChange={() => setFileErrors(validate(uploadFile))}
              onClick={(e) => {
                handleSubmit(e);
              }}
              appearance='primary'
              fluid
              size='large'
              children={t("pilotbtnsubmit.translated-text")}
            />
          </div>
        </div>
      </div>
      <div>
        {isSubmit &&
          filevalid &&
          (isMounted ? (
            !msgError && !overMaxNbRows ? (
              <div className={styles.containtdetail}>
                <div>
                  <div className='admintitle'>
                    {t("pilotsuccessmsg.translated-text")}
                  </div>
                </div>
                <div className='pt-4 d-flex flex-row'>
                  <div className='p-2 d-flex flex-column'>
                    <div className={`admintitle ${styles.detailcell}`}>
                      {t("pilottreat.translated-text")}
                    </div>
                    <div className={styles.successtext}>{getTotalTreat()}</div>
                  </div>
                  <div className='p-2 d-flex flex-column'>
                    <div className={`admintitle ${styles.detailcell}`}>
                      {t("pilotuntreated.translated-text")}
                    </div>
                    <div className={styles.untreatedtext}>
                      {getIntractable()}
                    </div>
                  </div>
                  <div className='p-2 d-flex flex-column'>
                    <div className={`admintitle ${styles.detailcell}`}>
                      {t("pilotinerror.translated-text")}
                    </div>
                    <div className={styles.errortext}>{getReject()}</div>
                  </div>
                  <div className='p-2 d-flex flex-column'>
                    <div className={`admintitle ${styles.detailcell}`}>
                      {t("pilottotalsent.translated-text")}
                    </div>
                    <div className={styles.totaltext}>{totalFilesRows}</div>
                  </div>
                </div>
                {(getIntractable() != 0 || getReject() != 0) && (
                  <div className='p-2'>
                    <div className='admintitle d-flex justify-content-start'>
                      {t("pilottitledetails.translated-text")}
                    </div>
                    <div>
                      <div className='d-flex flex-row'>
                        <div className={`admintitle ${styles.detailcell}`}>
                          {t("pilotqrcode.translated-text")}
                        </div>
                        <div className={`admintitle ${styles.detailcell}`}>
                          {t("pilotaction.translated-text")}
                        </div>
                      </div>
                    </div>
                    <div>
                      {buffresult.map((buffresult, index) => {
                        if (buffresult.reject.create.qrCode.length != 0) {
                          return (
                            <div key={index} className='d-flex flex-row'>
                              <div className={styles.detailcell}>
                                {buffresult.reject.create.qrCode}
                              </div>
                              <div className={styles.detailcell}>
                                {t("pilotcreation.translated-text")}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div>
                      {buffresult.map((buffresult, index) => {
                        if (buffresult.reject.update.qrCode.length != 0) {
                          return (
                            <div key={index} className='d-flex flex-row'>
                              <div className={styles.detailcell}>
                                {buffresult.reject.update.qrCode}
                              </div>
                              <div className={styles.detailcell}>
                                {t("pilotupdate.translated-text")}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div>
                      {buffresult.map((buffresult, index) => {
                        if (buffresult.reject.invalid.qrCode.length != 0) {
                          return (
                            <div key={index} className='d-flex flex-row'>
                              <div className={styles.detailcell}>
                                {buffresult.reject.invalid.qrCode}
                              </div>
                              <div className={styles.detailcell}>
                                {t("pilotinvalid.translated-text")}
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                )}
              </div>
            ) : msgError ? (
              <div>
                <Message appearance='error'>
                  {t("piloterrorsubmit.translated-text")}
                </Message>
              </div>
            ) : (
              <div>
                <Message appearance='error'>{msgOverMaxRows}</Message>
              </div>
            )
          ) : timeout ? (
            <Message appearance='error'>
              {t("pilottimeoutmsg.translated-text")}
            </Message>
          ) : (
            <div className={styles.progressimg}>
              <div>
                <ProgressSpinner size='xlarge' />
              </div>
            </div>
          ))}
      </div>

      <div>
        <input
          ref={fileRef}
          onChange={(e) => {
            readUploadFile(e);
          }}
          multiple={false}
          type='file'
          hidden
        />
      </div>
    </div>
  );
}
export default ManageQRCode;
