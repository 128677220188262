import React from "react";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Badge } from "@nbc-design/badge";
import styles from "./State.module.css";

function State({ dates, roomname, address, typeId }) {
  const { t } = useTranslation();
  const stateEquipment = JSON.parse(localStorage.getItem("EquipData"));
  let stateType = JSON.parse(localStorage.getItem("TypeData"));
  const screen = JSON.parse(localStorage.getItem("SelectScreen"));
  let startDate;
  let endDate;
  if (dates !== undefined) {
    startDate = dates.startDate;
    endDate = dates.endDate;
  }

  return (
    <div>
      <div className={`bg_screen ${styles.statecontainer}`}>
        <div className='bg_text d-flex flex-column justify-content-start'>
          {window.location.pathname !== "/protected" ? (

            <div className='row'>
              <div className='col-12'>
                <div className='roomname'>
                  <span>{roomname}</span>
                </div>
              </div>
              
            </div>
          ) : (
            <div className='roomname'>
              <span>{roomname}</span>{" "}
            </div>
          )}
          <div>
            <div>{screen.includes("/Screen04") ? (
              <div className='text-start'>{t("createticket.translated-text")}</div>
            ) : (
              ''
            )}
            {screen.includes("/Screen03") ? (
              <div className='text-start'>{t("howtolabel.translated-text")}</div>
            ) : (
              ''
            )}
            
            </div>
            <Breadcrumb
              startDate={dates !== undefined && startDate}
              endDate={dates !== undefined && endDate}
            />
          </div>
          <div>
            {screen === "/Screen03" || screen === "home" ? null : (
              <div className='d-flex flex-row justify-content-start badgesection'>
                {stateEquipment === "" && stateType === "" ? null : (
                  <div className='d-flex flex-row'>
                    {stateEquipment === "" ? null : (
                      <div className='pt-1'>
                        <Badge size='small' backgroundColor={"navy600"}>
                          {stateEquipment}
                        </Badge>
                      </div>
                    )}
                    {stateType === "" ? null : (
                      <div className='p-1 pb-0'>
                        <Badge size='small' backgroundColor={"navy600"}>
                          {stateType}
                        </Badge>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bg_screen'></div>
    </div>
  );
}
export default State;
