import { React } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ManageQrCode.module.css";
import { Button } from "@nbc-design/button";

function Accueil(props) {
  const { t } = useTranslation();

  return (
    <div className='centered-element'>
      <div className='bg_text d-flex flex-column justify-content-start'>
        <div className='roomname'>
          <span>{t("pilothometitle.translated-text")}</span>
        </div>
      </div>
      <div>
        <div className={`${styles.btnhomesection}`}>
          <div className='d-flex flex-row w-100 pt-3'>
            <div className='w-100'>
              <NavLink to={"/protected/"}>
                <Button
                  type='file'
                  key='accueil'
                  className='btntext is-padding'
                  appearance='primary'
                  fluid
                  size='large'
                  children={t("pilothomebtn.translated-text")}
                />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Accueil;
