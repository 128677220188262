import Category from "./Category";

const Categories = ({ categories, typeId, style, codeQR, ticketType,verbose }) => {
  return categories.map((category) => (
    <Category
      key={category.id}
      category={category}
      typeId={typeId}
      style={style}
      codeQR={codeQR}
      verbose={verbose}
    />
  ));
};

export default Categories;
