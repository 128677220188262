import React from "react";
import { useTranslation } from "react-i18next";
import { Confirmation } from "@nbc-design/confirmation";
import { Text } from "@nbc-design/text";
import styles from "../awserrorMsg/AwsErrorMsg.module.css";

function NotFoundMsg(props) {
  const { t } = useTranslation();
  const supportEmail = "valet@bnc.ca";

  return (
    <div className={`${styles.messagesection}`}>
      <div>
        {props.error.isError && (
          <Confirmation
            title={t("notfoundmsg.translated-text")}
            appearance='error'
          >
            <Text size='normal'>{t("notfoundmsgbottom.translated-text")} <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">{supportEmail}</a></Text>
          </Confirmation>
        )}
      </div>
    </div>
  );
}

export default NotFoundMsg;
