import React from "react";
import styles from "./Screen03.module.css";
import State from "../utils/state/State";
import { Document } from "@nbc-design/icons/lib/web/Document";
import { Demos } from "@nbc-design/icons/lib/web/Demos";
import { useTranslation } from "react-i18next";
import { Pdf } from "@nbc-design/icons/lib/web/Pdf";
import { ExternalLink } from "@nbc-design/icons/lib/web/ExternalLink";
import { Button } from "@nbc-design/button";
import axios from "axios";
import { NavLink } from "react-router-dom";
import AWS from "aws-sdk";
import aws4 from "aws4";
import { logCatchRequest } from "../../App";

function Screen03(props) {
  const { t } = useTranslation();

  const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });

  const hoststring = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathlog = process.env.REACT_APP_PATH_LOG;

  const logRetroRequest = (credential, QrCode, location) => {
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;

        let logdatabody = {
          type: "Applicatif",
          action: "Rétroaction",
          user: "system",
          details: "QrCode=" + QrCode + "; Localisation=" + location,
        };

        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let logsignedRequest = aws4.sign(logopts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];
        if (window.location.search.includes("&verbose=1")) {
          console.log(logdatabody, "verbose");
        }
        axios(logsignedRequest)
          .then(function (response) {})
          .catch(function (error) {
            logCatchRequest(credentials, QrCode, error);
          })
          .finally(() => {});
      }
    });
  };

  const logHelpInfos = (qr_Code, id, title, location, credential) => {
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;

        let logdatabody = {
          type: "Applicatif",
          action: "Aide",
          user: "system",
          details:
            "QrCode=" +
            qr_Code +
            "; Help Id=" +
            id +
            "; Description=" +
            title +
            " " +
            "; Localisation=" +
            location,
        };

        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let logsignedRequest = aws4.sign(logopts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];
        if (window.location.search.includes("&verbose=1")) {
          console.log(logdatabody, "verbose");
        }
        axios(logsignedRequest)
          .then(function (response) {})
          .catch(function (error) {
            logCatchRequest(credentials, qr_Code, error);
          })
          .finally(() => {});
      }
    });
  };

  const HeplList = ({ ...item }, lastitem, roomname, key) => {
    const separator =
      item.id == lastitem ? styles.divitemsnoborder : styles.divitems;
    const title =
      navigator.language.slice(0, 2) === "fr"
        ? item.DESCRIPTION_FR
        : item.DESCRIPTION_EN;
    const lien =
      navigator.language.slice(0, 2) === "fr"
        ? ".." + item.URL_FR
        : ".." + item.URL_EN;
    const funcGetPicto = (imageType) => {
      switch (imageType) {
        case "Pdf":
          return <Pdf size='large' className={styles.imgcolor} />;
        case "Video":
          return <Demos size='large' className={styles.imgcolor} />;
        case "Document":
          return <Document size='large' className={styles.imgcolor} />;
        default:
          return <ExternalLink size='large' className={styles.imgcolor} />;
      }
    };

    return (
      <div key={key} className='d-flex flex-row align-items-center'>
        <div className={`d-flex flex-items-start ${separator}`}>
          <div className='d-flex flex-row flex-items-start'>
            <div className={`d-flex align-middle ${styles.img03}`}>
              {funcGetPicto(item.IMAGE)}
            </div>

            <div className='d-flex flex-column flex-items-start'>
              <a
                id={item.id}
                className={styles.itemslink}
                href={lien}
                // target="_blank"
                onClick={() => {
                  logHelpInfos(
                    item.qr_code,
                    item.id,
                    title,
                    roomname,
                    AWS.config.credentials
                  );
                }}
              >
                <span className={styles.itemstitle}>{title}</span>
              </a>
              <div className=' d-flex flex-items-start'>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const items = props.qrData;
  const lastitem = items.at(-1);

  return (
    <div className='bg_screen'>
      <div className='centered-element'>
        <div>
          <State
            roomname={props.roomname}
            address={props.address}
            typeId={props.typeId}
          />
        </div>
        <div>
          
          <div className='form-group d-flex flex-column align-items-center middlesection'>
            <div>
              <div className={styles.itemsection}>
                <div className={styles.divcontent03}>
                  {items.map((item) =>
                    HeplList({ ...item }, lastitem.id, props.roomname, item.id)
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'></div>
          <div>
            <div className='d-flex justify-content-center'>
              <div className='d-flex flex-row aling-items-center'>
                <ExternalLink size='small' className={styles.imgcolorsmall} />
                <a
                  onClick={() => {
                    logRetroRequest(
                      AWS.config.credentials,
                      props.codeQR,
                      props.roomname
                    );
                  }}
                  className={styles.linktext}
                  target='_blank'
                  href={props.feedbackLink}
                >
                  {t("feedback.translated-text")}
                </a>
              </div>
            </div>
            <div className='pt-3  d-flex justify-content-center'>
              <NavLink
                to={`/?uid=${props.codeQR}`}
                className={styles.navbutton}
              >
                <Button
                  className='btntext is-padding'
                  onClick={() => {
                    localStorage.setItem(
                      "SelectScreen",
                      JSON.stringify("home")
                    );
                    localStorage.setItem("NoSection", JSON.stringify(1));
                  }}
                  appearance='primary'
                  fluid
                  size='large'
                  children={t("btnbacktohome.translated-text")}
                />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Screen03;
