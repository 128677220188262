import React from "react";
import { useTranslation } from "react-i18next";
import { Confirmation } from "@nbc-design/confirmation";
import { Text } from "@nbc-design/text";
import styles from "./AwsErrorMsg.module.css";

function ScreenMsg(props) {
  const { t } = useTranslation();

  //Reset breadcrumb

  return (
    <div className={`bg_white ${styles.messagesection}`}>
      <div className='centered-element'>
        <Confirmation
          title={t("awserrormsg.translated-text")}
          appearance='error'
        >
          <Text size='normal'>{t("awserrorbottommsg.translated-text")}</Text>
        </Confirmation>
      </div>
    </div>
  );
}

export default ScreenMsg;
