const INITIAL_STATE = {
  stateSectionNo: 1,
};

function SectionNoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADDSECNO": {
      return {
        ...state,
        //stateContext: [...state.stateContext, action.payload],
        stateSectionNo: action.payload,
      };
    }
  }
  return state;
}
export default SectionNoReducer;
