import { Card } from "@nbc-design/card";
import { Icon } from "@nbc-design/icons";
import { ArrowRight } from "@nbc-design/icons/lib/web/ArrowRight";
import { NavLink, json, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AWS from "aws-sdk";
import aws4 from "aws4";
import axios from "axios";
import { logCatchRequest } from "../../App";
import i18n from "i18next";

const Category = ({ category, typeId, style, ticketType, codeQR, verbose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ticketmode = (ticketTypevalue) => {
    // return ticketTypevalue == 3 ? "/Screen04/equipment" : "/Screen01/form01";
    return ticketTypevalue != 1
      ? `/Screen04/equipment/?uid=${codeQR}`
      : `/Screen01/form01/?uid=${codeQR}`;
  };
  const ticketmodeString = (ticketTypevalue) => {
    return ticketTypevalue != 1
      ? `/Screen04/equipment/?uid=${codeQR}`
      : `/Screen01/form01/?uid=${codeQR}`;
    // return ticketTypevalue == 3 ? "/Screen04/equipment" : "/Screen01/form01";
  };
  const getTypesOfProblems = (category) => {
    let equipements = {};
    const hoststring = process.env.REACT_APP_HOST_STRING;
    const urldest = process.env.REACT_APP_URL_DEST;
    const pathCategory = process.env.REACT_APP_PATH_CATEGORY;
    const pathlog = process.env.REACT_APP_PATH_LOG;
    const usedConfig = {
      region: process.env.REACT_APP_CO_REGION,
      identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
    };
    AWS.config.region = usedConfig.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: usedConfig.identityPoolId,
    });

    AWS.config.credentials.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = AWS.config.credentials;

        let opts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathCategory + category.id,
          path: pathCategory + category.id,
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          data: { typeId },
          body: JSON.stringify({ typeId }), // aws4 looks for body; axios for data, need to set both
          headers: {
            "content-type": "application/json",
          },
        };
        let signedRequest = aws4.sign(opts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });
        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete signedRequest.headers["Host"];
        delete signedRequest.headers["Content-Length"];

        axios(signedRequest)
          .then(function (response) {
            const responseData = response.data;

            // Extraire la liste d'équipements et la variable display_contact_info
            const displayContactInfo = responseData.display_contact_info;
            localStorage.setItem(
              "displayContactInfo",
              JSON.stringify(displayContactInfo)
            );

            const equipementsList = responseData.equipements;

            let equipements = equipementsList.map((equipement) => {
              return {
                key: equipement.id,
                value:
                  navigator.language.slice(0, 2) === "en"
                    ? equipement.name_en
                    : equipement.name_fr,
                name: "equipementpb",
                type_problemes: equipement.type_problemes,
              };
            });
            localStorage.setItem("allEquipments", JSON.stringify(equipements));
            // navigate(ticketmodeString(ticketType));
            // Modify the URL based on the verbose prop
            let destination = verbose
              ? `${ticketmodeString(ticketType)}&verbose=1`
              : ticketmodeString(ticketType);

            navigate(destination);
          })
          .catch(function (error) {
            logCatchRequest(credentials, codeQR, error);
          })
          .finally(() => {});
      }
    });
  };
  return (
    <div className='card' style={{ margin: "5px auto", padding: "2px" }}
      onClick={() => {
        localStorage.setItem(
          "SelectScreen",
          JSON.stringify(ticketmodeString(ticketType))
        );
        localStorage.setItem("NoSection", JSON.stringify(1));
        localStorage.setItem(
          "selectedCategory",
          JSON.stringify(category)
        );
        getTypesOfProblems(category);
      }}
    >
      <div className='row align-items-center'>
        <div className='col-3'>
          <img
            src={"/media_content/" + category.image}
            style={{ width: "100%", maxWidth: "120px", padding: "2px" }}
          />
        </div>
        <div className='col-7'>
          <span
            className='text-wrap'
            style={{ color: "#1572c5", fontSize: "1rem" }}
          >
            {navigator.language.slice(0, 2) === "en" ? category.name_en : category.name_fr}
          </span>
        </div>
        <div className='col-2'>
          <div id='arrow' className={style.arrowSection}>
            <ArrowRight
              size='medium'
              title={
                navigator.language.slice(0, 2) === "en" ? category.name_en : category.name_fr
              }
              onMouseOver={() => {}}
              
              value='slide-right'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
