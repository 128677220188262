import React from "react";
import { useTranslation } from "react-i18next";
import { Confirmation } from "@nbc-design/confirmation";
import { useDispatch } from "react-redux";
import { Text } from "@nbc-design/text";
import { NavLink } from "react-router-dom";
import { Button } from "@nbc-design/button";
import styles from "./InactiveMsg.module.css";

function InactiveMsg(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ticketmode = (ticketTypevalue) => {
    return ticketTypevalue != 1 ? "/Screen04" : "/Screen01";
  };

  //Reset breadcrumb
  const addScreenSelected = (screenValue) => {
    dispatch({
      type: "ADDSCREEN",
      payload: screenValue,
    });
  };
  const addEquipmentData = () => {
    dispatch({
      type: "ADDTAGEQUI",
      payload: "",
    });
  };
  const addTypeData = () => {
    dispatch({
      type: "ADDTAGTYPE",
      payload: "",
    });
  };

  return (
    <div className="bg_white centered-element">
      <div className="p-4">
        <Confirmation
          title={t("inactivemsg.translated-text")}
          appearance="error"
        >
          <Text size="normal">{t("directivemsg.translated-text")}</Text>
        </Confirmation>
      </div>
    </div>
  );
}

export default InactiveMsg;
