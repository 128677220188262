import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Breadcrumb.module.css";

function Breadcrumb({ startDate, endDate }) {
  const { t } = useTranslation();

  return (
    <div>
      <span className={styles.libread}>
        {window.location.pathname === "/protected"
          ?`${startDate !== "" ? startDate.split("T")[0] : "yyyy-mm-dd"} ${
            startDate !== "" ? startDate.split("T")[1] : "--:--:-- --"
          } | ${endDate !== "" ? endDate.split("T")[0] : "yyyy-mm-dd"} ${
            endDate !== "" ? endDate.split("T")[1] : "--:--:-- --"
          } `
          :""}
      </span>
    </div>
  );
}
export default Breadcrumb;
