
import React, { useEffect, useState } from "react";
import styles from "./Screen04.module.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@nbc-design/button";
import { Message } from "@nbc-design/message";
import { CardButton } from "@nbc-design/card";
import { Input } from "@nbc-design/input";
import { Fieldset } from "@nbc-design/fieldset";
import { FormGroup } from "@nbc-design/form-group";
import { Radio } from "@nbc-design/radio";
import { ArrowLeft } from "@nbc-design/icons/lib/web/ArrowLeft";
import { useDispatch } from "react-redux";
import State from "../utils/state/State";
import ProgressLoading from "../utils/progressLoading/ProgressLoading";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "axios";
import AWS from "aws-sdk";
import aws4 from "aws4";
import i18n from "i18next";

function Screen04(props) {
  localStorage.setItem("SelectScreen", JSON.stringify("/Screen04/equipment"));
  const [equipementsArray, setEquipementsArray] = useState([]);
  const [dropdownOptionsType, setDropdownOptionsType] = useState([
    { name: "typepb", key: "audio", value: "audio" },
    { name: "typepb", key: "video", value: "video" },
    { name: "typepb", key: "control", value: "controle" },
    { name: "typepb", key: "network", value: "reseau" },
    { name: "typepb", key: "other", value: "autre" },
  ]);
  const { target } = props;
  const usedConfig = {
    region: process.env.REACT_APP_CO_REGION,
    identityPoolId: process.env.REACT_APP_CO_IDENTITY_POOL_ID,
  };

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = usedConfig.region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: usedConfig.identityPoolId,
  });

  const hoststring = process.env.REACT_APP_HOST_STRING;
  const urldest = process.env.REACT_APP_URL_DEST;
  const pathlog = process.env.REACT_APP_PATH_LOG;
  const pathIncident = process.env.REACT_APP_PATH_INCIDENT;
  const { t } = useTranslation();

  const [showInfos, setShowInfos] = useState(false);
  const onClick = () =>
    setShowInfos(localStorage.setItem("showInfos", JSON.stringify(true)));
  const onClickNo = () =>
    setShowInfos(localStorage.setItem("showInfos", JSON.stringify(false)));

  const [showType, setShowType] = useState(true);
  const [isError, setisError] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [showInfoSection, setShowInfoSection] = useState(true);
  const [DataisLoaded, setDataisLoaded] = useState(false);
  const [incidentInfos, setIncidentInfos] = useState([]);
  const [formSecNum, setFormSecNum] = useState(
    JSON.parse(localStorage.getItem("NoSection"))
  );
  const [description, setDescription] = useState("");
  const [adressCourrielForm, setAdressCourrielForm] = useState("");
  const [telNum, setTelNum] = useState("");
  const [selectedtype, setSelectedtype] = useState("");
  const [selectedequip, setSelectedequip] = useState("");
  const [selectedradio, setSelectedradio] = useState("");
  const [transitionmode, setTransitionmode] = useState("fadeff");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const addEquipmentData = (EquipmentValue) => {
    dispatch({
      type: "ADDTAGEQUI",
      payload: EquipmentValue,
    });
  };

  const addTypeData = (TypeValue) => {
    dispatch({
      type: "ADDTAGTYPE",
      payload: TypeValue,
    });
  };

  const addSecNo = (SecNo) => {
    dispatch({
      type: "ADDSECNO",
      payload: SecNo,
    });
  };
  const addScreenSelected = (screenValue) => {
    dispatch({
      type: "ADDSCREEN",
      payload: screenValue,
    });
  };
  const addNoIncident = (NoIncident) => {
    dispatch({
      type: "ADDNOINC",
      payload: NoIncident,
    });
  };
  const [qrCode, setqrCode] = useState([]);

  const logCatchRequest = (credential, codeQR, location, values, error) => {
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;
        const { accessKeyId } = credentials;
        let equipmentLabel = "";

        let typeLabel = "";
        switch (values.equipementpb) {
          case "monitor": {
            equipmentLabel = t("monitor.translated-text", { lng: "fr" });
            break;
          }
          case "soundbar": {
            equipmentLabel = t("soundbar.translated-text", { lng: "fr" });
            break;
          }
          case "polycom": {
            equipmentLabel = t("polycom.translated-text", { lng: "fr" });
            break;
          }
          case "unknown": {
            equipmentLabel = t("unknown.translated-text", { lng: "fr" });
            break;
          }
          default: {
            equipmentLabel = values.equipementpb;
          }
        }

        switch (values.typepb) {
          case "audio": {
            typeLabel = t("audio.translated-text", { lng: "fr" });
            break;
          }
          case "video": {
            typeLabel = t("video.translated-text", { lng: "fr" });
            break;
          }
          case "control": {
            typeLabel = t("control.translated-text", { lng: "fr" });
            break;
          }
          case "network": {
            typeLabel = t("unknown.translated-text", { lng: "fr" });
            break;
          }
          default: {
            typeLabel = t("other.translated-text", { lng: "fr" });
          }
        }

        let logdatabody = {
          type: "Applicatif",
          action: "Erreur",
          user: "system",
          sessionToken: accessKeyId,
          details:
            "Endpoint=" +
            urldest +
            pathIncident +
            ";QrCode= " +
            codeQR +
            ", Localisation : " +
            location +
            ", Équipement : " +
            equipmentLabel +
            ", Type : " +
            typeLabel +
            ", Erreur= " +
            error,
        };

        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let logsignedRequest = aws4.sign(logopts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });
        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];
        if (window.location.search.includes("&verbose=1")) {
          console.log(logdatabody, "verbose");
        }
        axios(logsignedRequest)
          .then(function (response) {
            //console.log(response);
          })
          .catch(function (error) {})
          .finally(() => {
            // console.log("log error final");
          });
      }
    });
    
  };

  const logGetRequest = (
    codeQR,
    values,
    location,
    status,
    incidentNum,
    credential
  ) => {
    credential.get(function (err) {
      if (err) {
        //Handle error
      } else {
        const credentials = credential;
        const { accessKeyId } = credentials;
        let logdatabody = {
          type: "Applicatif",
          action: "Créer billet",
          user: "system",
          sessionToken: accessKeyId,
          details:
            "QrCode=" +
            codeQR +
            "; Appel=GET" +
            "; Equipement=" +
            values.equipementpb +
            "; Localisation=" +
            location +
            "; Status=" +
            status +
            "; No incident=" +
            incidentNum,
        };

        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };

        let logsignedRequest = aws4.sign(logopts, {
          // assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });
        //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
        delete logsignedRequest.headers["Host"];
        delete logsignedRequest.headers["Content-Length"];
        if (window.location.search.includes("&verbose=1")) {
          console.log(logdatabody, "verbose");
        }
        axios(logsignedRequest)
          .then(function (response) {
            // console.log(response);
          })
          .catch(function (error) {
            logCatchRequest(credentials, qrCode, location, values, error);
          })
          .finally(() => {});
      }
    });
  };
  const logPostRequest = (
    codeQR,
    values,
    location,
    status,
    incidentNum,
    credential
  ) => {
    // Get credentials to make AWS API call
    credential.get(function (err) {
      if (err) {
        //Handle error when unable to get credentials
        console.error("Error: Unable to get credentials");
      } else {
        const credentials = credential;
        const { accessKeyId } = credentials;
        // Define the log data to be sent in the request
        let logdatabody = {
          type: "Applicatif",
          action: "Créer billet",
          user: "system",
          sessionToken: accessKeyId,
          details:
            "QrCode=" +
            codeQR +
            "; Appel=POST" +
            "; Equipement=" +
            values.equipementpb +
            "; Localisation=" +
            location +
            "; Status=" +
            status +
            "; No incident=" +
            incidentNum,
        };

        // Define the options for the API call
        let logopts = {
          host: hoststring,
          method: "POST",
          url: urldest + pathlog,
          path: pathlog,
          data: logdatabody,
          body: JSON.stringify(logdatabody), // aws4 looks for body; axios for data, need to set both
          service: "execute-api",
          region: process.env.REACT_APP_CO_REGION,
          headers: {
            "content-type": "application/json",
          },
        };


        // AWS4 requires the region and service to sign the request
        aws4.sign(logopts, {
          // Assumes user has authenticated and we have called
          // AWS.config.credentials.get to retrieve keys and
          // session tokens
          secretAccessKey: credentials.secretAccessKey,
          accessKeyId: credentials.accessKeyId,
          sessionToken: credentials.sessionToken,
        });

        // Remove headers that are not allowed by some browsers
        delete logopts.headers["Host"];
        delete logopts.headers["Content-Length"];
        if (window.location.search.includes("&verbose=1")) {
          console.log(logdatabody, "verbose");
        }
        // Send the API request using axios
        axios(logopts)
          .then(function (response) {})
          .catch(function (error) {
            logCatchRequest(credentials, qrCode, location, values, error);
          });
      }
    });
  };

  const submitFormRequest = (values, credential) => {
    // Scanned QRCode
    const category = JSON.parse(localStorage.getItem("selectedCategory"));
    const problems = JSON.parse(localStorage.getItem("allTypesOfProblems"));
    const problem = problems ? problems.filter((x) => x.value.normalize('NFD').replace(/\p{Diacritic}/gu, '') === values.typepb.normalize('NFD').replace(/\p{Diacritic}/gu, ''))[0] : 0;
    const equipement = JSON.parse(localStorage.getItem("EquipDataID"));

    if (!equipement) {
      logCatchRequest(
        AWS.config.credentials,
        props.codeQR,
        props.roomname,
        formValues,
        `Erreur lors de la création d'un incident. EquipmentId n'est pas défini. Category = ${localStorage.getItem("selectedCategory")}; Equipments = ${localStorage.getItem("allEquipments")}; Selected Equipment : ${values.equipementpb}`,
      );
      navigate("/ErrorMsg");
    } else {
      const payload = {
        equipementId: equipement,
        typeOfProblemId: problem,
        commentary: values.desDetPbForm,
        contactEmail: values.adressCourrielForm,
        contactNumber: values.telNum,
        qrCode: props.codeQR,
        categoryId: category.id,
      };
  
      credential.get(function (err) {
        if (err) {
          //Handle error
        } else {
          const credentials = credential;
  
          //Portion pour obtenir les données d'un code QR
  
          let opts = {
            host: hoststring,
            method: "POST",
            url: urldest + pathIncident,
            path: pathIncident,
            data: payload,
            body: JSON.stringify(payload), // aws4 looks for body; axios for data, need to set both
            service: "execute-api",
            region: process.env.REACT_APP_CO_REGION,
            headers: {
              "content-type": "application/json",
            },
          };
  
          let signedRequest = aws4.sign(opts, {
            // assumes user has authenticated and we have called
            // AWS.config.credentials.get to retrieve keys and
            // session tokens
            secretAccessKey: credentials.secretAccessKey,
            accessKeyId: credentials.accessKeyId,
            sessionToken: credentials.sessionToken,
          });
  
          //some browsers don't allow setting these headers...removing them in order to not have the error/warning in the console
          delete signedRequest.headers["Host"];
          delete signedRequest.headers["Content-Length"];
          if (window.location.search.includes("&verbose=1")) {
            console.log(payload, "verbose");
          }
  
          axios(signedRequest)
            .then(
              (response) => setIncidentInfos(response.data),
              //console.log(response),
              setDataisLoaded(true)
            )
  
            .catch((error) => {
              logCatchRequest(
                AWS.config.credentials,
                props.codeQR,
                props.roomname,
                formValues,
                error
              );
              navigate("/ErrorMsg");
            });
        }
      });
    }
  };

  useEffect(() => {
    if (DataisLoaded && incidentInfos.Success == "Ok") {
      logGetRequest(
        props.codeQR,
        formValues,
        props.roomname,
        incidentInfos.Status,
        incidentInfos.Ticket,
        AWS.config.credentials
      );
      addNoIncident(incidentInfos.Ticket);
      if (incidentInfos.Mode == "Exist") {
        navigate(`/existsMsg/?uid=${props.codeQR}`);
      } else {
        logPostRequest(
          props.codeQR,
          formValues,
          props.roomname,
          incidentInfos.Status,
          incidentInfos.Ticket,
          AWS.config.credentials
        );
        navigate(`/successMsg/?uid=${props.codeQR}`);
      }
    } else {
      if (DataisLoaded) {
        logCatchRequest(
          AWS.config.credentials,
          props.codeQR,
          props.roomname,
          formValues,
          ""
        );
        navigate(`/ErrorMsg/?uid=${props.codeQR}`);
      } else {
      }
    }
  }, [incidentInfos]);

  const nextPage = () => {
    let page;
  
    const currentSearchParams = new URLSearchParams(location.search);
    const isVerbose = currentSearchParams.get('verbose') === '1';
    
    // Remove the 'uid' parameter from currentSearchParams to avoid duplication
    currentSearchParams.delete('uid');
    
    switch (formSecNum) {
      case 1:
        // Check if equipment is selected
        if (formValues.equipementpb === "") {
          // Do nothing
        } else {
          // Get the selected equipment object and extract its type_problemes
          const equipement = equipementsArray.find(
            (e) => e.value === selectedequip
          );
  
          const typesOfProblems = equipement.type_problemes.map((element) => {
            return {
              key: element.id,
              name: "typepb",
              value:
                navigator.language.slice(0, 2) === "en" ? element.name_en : element.name_fr,
            };
          });
  
          if (typesOfProblems.length > 0) {
            // Continue with the normal behavior
            setShowType(true);
            setFormSecNum(2);
            localStorage.setItem("NoSection", JSON.stringify(2));
            setDropdownOptionsType(typesOfProblems);
            if (JSON.parse(localStorage.getItem("allTypesOfProblems")) === null) {
              localStorage.setItem(
                "allTypesOfProblems",
                JSON.stringify(typesOfProblems)
              );
            }
            // Clear form errors and navigate to next page
            setFormErrors({});
            page = `/Screen04/type/?uid=${props.codeQR}`;
          } else {
            // Skip the "Screen04/type/" page and directly move to the next section
            setShowDescription(true);
            setFormSecNum(3);
            localStorage.setItem("NoSection", JSON.stringify(3));
  
            // Clear form errors and navigate to next page
            setFormErrors({});
            page = `/Screen04/description/?uid=${props.codeQR}`;
          }
        }
        break;
  
      case 2:
        // Check if type of problem is selected
        if (formValues.typepb === "") {
          // Do nothing
        } else {
          // Set showDescription to true, move to next section, and store section number in localStorage
          setShowDescription(true);
          setFormSecNum(3);
          localStorage.setItem("NoSection", JSON.stringify(3));
  
          // Clear form errors and navigate to next page
          setFormErrors({});
          page = `/Screen04/description/?uid=${props.codeQR}`;
        }
        break;
  
      case 3:
        // Check if problem description is provided
        if (formValues.desDetPbForm === "") {
          // Do nothing
        } else {
          // Set showInfoSection to true, move to next section, store section number in localStorage, and increment section count
          setShowInfoSection(true);
          setFormSecNum(4);
          localStorage.setItem("NoSection", JSON.stringify(4));
          addSecNo(4);
  
          // Clear form errors and navigate to next page
          setFormErrors({});
          

          page = `/Screen04/userinfos/?uid=${props.codeQR}`;

        }
        break;
  
      default:
        // If formSecNum is not 1, 2, or 3, set formSecNum to 5 and increment section count
        setFormSecNum(5);
        addSecNo(5);
    }
  
    // Return the page value and navigate
    if (isVerbose && currentSearchParams.toString()) {
      page += `&${currentSearchParams.toString()}`;
    }
    
    return navigate(page);
  };
  

  const prevPage = (secNum) => {
    let page;
  
    const currentSearchParams = new URLSearchParams(location.search);
    const isVerbose = currentSearchParams.get('verbose') === '1';
  
    // Remove the 'uid' parameter from currentSearchParams to avoid duplication
    currentSearchParams.delete('uid');
      const selectedEquip = JSON.parse(localStorage.getItem("selectedEquip"));
      const selectedEquipment = equipementsArray.find(
    (e) => e.value === selectedEquip
     );
    const typeProblemsEmpty = selectedEquipment && selectedEquipment.type_problemes.length === 0;
    switch (secNum) {
      case 1: {
        // If going back from the first section, reset the equipment data and selected equipment
        setShowType(true);
        addScreenSelected("home");
        page = `/?uid=${props.codeQR}`;
        localStorage.setItem("EquipData", JSON.stringify(""));
        localStorage.setItem("EquipDataID", JSON.stringify(""));
        localStorage.setItem("selectedEquip", JSON.stringify(""));
        localStorage.setItem("TypeData", JSON.stringify(""));
        localStorage.setItem("selectedType", JSON.stringify(""));
        localStorage.removeItem("allTypesOfProblems");
        localStorage.removeItem("allEquipments");
        setSelectedequip(false);
        break;
      }
      case 2: {
        // If going back from the second section, reset the type data and selected type
  
        page = `/Screen04/equipment/?uid=${props.codeQR}`;
        setFormErrors({});
        setFormSecNum(1);
        addSecNo(1);
        let obj = JSON.parse(localStorage.getItem("allValues"));
        obj.typepb = 0;
        localStorage.setItem("allValues", JSON.stringify(obj));
        let frmValues = formValues;
        frmValues.typepb = 0;
        setFormValues(frmValues);
        localStorage.setItem("NoSection", JSON.stringify(1));
        localStorage.setItem("TypeData", JSON.stringify(""));
        localStorage.setItem("selectedType", JSON.stringify(""));
        localStorage.removeItem("allTypesOfProblems");
        setSelectedtype(false);
        break;
      }
      case 3: {
        // If going back from the third section, reset the description data
        if (typeProblemsEmpty) {
          secNum = 2;
        }
  
        page = typeProblemsEmpty ? `/Screen04/equipment/?uid=${props.codeQR}` : `/Screen04/type/?uid=${props.codeQR}`;
        setFormErrors({});
        setFormSecNum(typeProblemsEmpty ? 1 : 2);
        addSecNo(typeProblemsEmpty ? 1 : 2);
        localStorage.setItem("NoSection", JSON.stringify(typeProblemsEmpty ? 1 : 2));
        break;
      }
      default: {
        // If going back from the fourth section or beyond, reset the form errors and go to the previous section
        page = `/Screen04/description/?uid=${props.codeQR}`;
        setFormErrors({});
        setFormSecNum(3);
        addSecNo(3);
        localStorage.setItem("NoSection", JSON.stringify(3));
        break;
      }
    }
  
    if (isVerbose && currentSearchParams.toString()) {
      page += `&${currentSearchParams.toString()}`;
    }
  
    return navigate(page);
  };
  window.onpopstate = (e) => {
    prevPage(formSecNum);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  window.onpushstate = () => {};

  const initialvalues = {
    equipementpb: "",
    typepb: 0,
    desDetPbForm: "",
    radioContact: "",
    adressCourrielForm: "",
    telNum: "",
    transitionmode: "slide-left",
  };

  const [formValues, setFormValues] = useState(initialvalues);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //setFormValues({ ...formValues, [name]: value.split('\n').join(' ').normalize('NFD').replace(/[\u0300-\u036f]/g, "") });
    localStorage.setItem(
      "allValues",
      JSON.stringify({
        ...formValues,
        [name]: value
          .split("\n")
          .join(" ")
          .replace(/[\u0300-\u036f]/g, ""),
      })
    );
    // setFormValues(JSON.parse(localStorage.getItem('allValues')));
  };

  const handleChangeType = (event) => {
    //setSelectedtype(event.target.value);
    localStorage.setItem("selectedType", JSON.stringify(event.target.value));
  };

  const handleChangeequip = (event) => {
    //setSelectedequip(event.target.value);
    localStorage.setItem("selectedEquip", JSON.stringify(event.target.value));
  };
  const handleChangedescription = (event) => {
    //setSelectedequip(event.target.value);
    localStorage.setItem("description", JSON.stringify(event.target.value));
  };

  const handleChangeRadio = (event) => {
    setSelectedradio(event.target.value);
    localStorage.setItem("selectedRadio", JSON.stringify(event.target.value));
  };

  const handleChangeNavButton = (e) => {
    setTransitionmode(e);
  };
  useEffect(() => {
    //localStorage.setItem('allValues',JSON.stringify(initialvalues))
    setFormValues(JSON.parse(localStorage.getItem("allValues")));
    setSelectedequip(JSON.parse(localStorage.getItem("selectedEquip")));
    setSelectedtype(JSON.parse(localStorage.getItem("selectedType")));
    setSelectedradio(JSON.parse(localStorage.getItem("selectedRadio")));
    setDescription(JSON.parse(localStorage.getItem("description")));
    setAdressCourrielForm(JSON.parse(localStorage.getItem("courriel")));
    setTelNum(JSON.parse(localStorage.getItem("telNum")));
    setShowInfos(JSON.parse(localStorage.getItem("showInfos")));
    setEquipementsArray(JSON.parse(localStorage.getItem("allEquipments")));
    //const toto = localStorage.getItem("allValues");
    if (equipementsArray !== null) {
      let allTypesOfProblems = JSON.parse(
        localStorage.getItem("allTypesOfProblems")
      );
      let typesOfProblems = [];
      if (allTypesOfProblems != null) {
        typesOfProblems = allTypesOfProblems;
        setDropdownOptionsType(allTypesOfProblems);
      }
    }
    let isError = JSON.parse(localStorage.getItem("error"));
    if (isError) setisError(true);
    if (
      localStorage.getItem("EquipData") === null ||
      localStorage.getItem("TypeData") === null
    ) {
      prevPage(1);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      prevPage(1);
      localStorage.removeItem("error");
    }
  }, [isError]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    setIsSubmit(true);
    if (Object.keys(formErrors).length === 0) {
      submitFormRequest(formValues, AWS.config.credentials);
    }
    localStorage.setItem("allValues", JSON.stringify(initialvalues));
  };

  // Validate form feilds
  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regextel =
      /(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
    if (!values.equipementpb) {
      errors.equipementpb = (
        <Message appearance='error'>
          {t("validateequipment.translated-text")}
        </Message>
      );
    }
    /*if (!values.typepb) {
      errors.typepb = (
        <Message appearance='error'>
          {t("validatetype.translated-text")}
        </Message>
      );
    }*/
    if (!values.desDetPbForm) {
      errors.desDetPbForm = (
        <Message appearance='error'>
          {t("validatedesc.translated-text")}
        </Message>
      );
    }
    if (!values.radioContact) {
      errors.radioContact = (
        <Message appearance='error'>
          {t("validateradio.translated-text")}
        </Message>
      );
    }
    if (values.radioContact == "yes") {
      if (!values.adressCourrielForm) {
        errors.adressCourrielForm = (
          <Message appearance='error'>
            {t("validatemail.translated-text")}
          </Message>
        );
      } else if (!regex.test(values.adressCourrielForm)) {
        errors.adressCourrielForm = (
          <Message appearance='error'>
            {t("validatemailformat.translated-text")}
          </Message>
        );
      }
      if (!values.telNum) {
        errors.telNum = (
          <Message appearance='error'>
            {t("validatetelnum.translated-text")}
          </Message>
        );
      } else if (!regextel.test(values.telNum)) {
        errors.telNum = (
          <Message appearance='error'>
            {t("validatetelnumformat.translated-text")}
          </Message>
        );
      }
    }
    return errors;
  };
  if (DataisLoaded) {
    return (
      <div className='bg_screen App'>
        <ProgressLoading />
      </div>
    );
  } else {
    return (
      <div className='bg_screen'>
        <div className='centered-element' id='top'>
          <div>
            <State
              roomname={props.roomname}
              address={props.address}
              typeId={props.typeId}
            />
          </div>

          <div className='btnsection'>
            <form
              id='incidentForm'
              onSubmit={handleSubmit}
              className='bg_screen'
            >
              <div>
                <TransitionGroup component={null}>
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames={transitionmode}
                    unmountOnExit
                  >
                    <Routes location={location}>
                      <Route
                        path='equipment'
                        element={
                          <div id='equipement' className='contentbutton'>
                            <div
                              className='d-flex flex-column justify-content-start'
                              style={{ marginBottom: "15%" }}
                            >
                              <label className='formselect'>
                                {t("equipmentpblabel.translated-text")}
                              </label>
                              <div>
                                {equipementsArray != null &&
                                  equipementsArray.length > 0 &&
                                  equipementsArray.map((option) => {
                                    return (
                                      <div key={option.key}>
                                        <CardButton
                                          key={option.key}
                                          className='spc-card-button'
                                          name={option.name}
                                          id={option.value}
                                          checked={
                                            selectedequip == option.value
                                          }
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeequip(e);
                                            setFormValues(
                                              JSON.parse(
                                                localStorage.getItem(
                                                  "allValues"
                                                )
                                              )
                                            );
                                            setSelectedequip(
                                              JSON.parse(
                                                localStorage.getItem(
                                                  "selectedEquip"
                                                )
                                              )
                                            );
                                          }}
                                          onClick={(e) => {
                                            localStorage.setItem(
                                              "EquipData",
                                              JSON.stringify(option.value)
                                            );
                                            localStorage.setItem(
                                              "EquipDataID",
                                              JSON.stringify(option.key)
                                            );
                                            addEquipmentData(option.value);
                                            // setShowType(true);
                                          }}
                                          value={option.value}
                                          role='radio'
                                          title={option.value}
                                        />
                                      </div>
                                    );
                                  })}
                                <p>{formErrors.equipementpb}</p>
                              </div>
                            </div>
                          </div>
                        }
                      />
                      <Route
                        path='type'
                        element={
                          <div id='type' className='contentbutton'>
                            <div
                              className='d-flex flex-column justify-content-start'
                              style={{ marginBottom: "15%" }}
                            >
                              <label className='formselect'>
                                {t("typepblabbel.translated-text")}
                              </label>
                              <div>
                                {dropdownOptionsType &&
                                  dropdownOptionsType.length > 0 &&
                                  dropdownOptionsType.map((option) => {
                                    return (
                                      <div
                                        className='contentbutton'
                                        key={option.value}
                                      >
                                        <CardButton
                                          key={option.key}
                                          className='spc-card-button'
                                          name={option.name}
                                          id={option.value}
                                          checked={selectedtype == option.value}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeType(e);
                                            setFormValues(
                                              JSON.parse(
                                                localStorage.getItem(
                                                  "allValues"
                                                )
                                              )
                                            );
                                            setSelectedtype(
                                              JSON.parse(
                                                localStorage.getItem(
                                                  "selectedType"
                                                )
                                              )
                                            );
                                          }}
                                          onClick={(e) => {
                                            localStorage.setItem(
                                              "TypeData",
                                              JSON.stringify(option.value)
                                            );
                                            addTypeData(option.value);
                                          }}
                                          value={option.value}
                                          role='radio'
                                          title={t(option.value)}
                                        />
                                      </div>
                                    );
                                  })}
                                <p>{formErrors.typepb}</p>
                              </div>
                            </div>
                          </div>
                        }
                      />

                      <Route
                        path='description'
                        element={
                          <div id='description' className='contentbutton'>
                            <div className='form-group formselectsingle d-flex flex-column align-items-start'>
                              <label
                                className={`formselect ${styles.textarealabel}`}
                              >
                                {t("dtldescPb.translated-text")}
                              </label>

                              <textarea
                                name='desDetPbForm'
                                value={formValues.desDetPbForm}
                                rows='5'
                                placeholder={t(
                                  "dtldescPbPholder.translated-text"
                                )}
                                className='form-control-description'
                                onChange={(e) => {
                                  handleChange(e);
                                  setFormValues(
                                    JSON.parse(
                                      localStorage.getItem("allValues")
                                    )
                                  );
                                  setDescription(
                                    JSON.parse(
                                      localStorage.getItem("description")
                                    )
                                  );
                                }}
                              />
                            </div>
                            <p>{formErrors.desDetPbForm}</p>
                          </div>
                        }
                      />

                      <Route
                        path='userinfos'
                        element={
                          <div className='form-group formselectsingle d-flex flex-column align-items-start contentbutton'>
                            <div>
                              <Fieldset
                                id='a'
                                optionalText=''
                                legend={t("radioQuestion.translated-text")}
                              >
                                <Radio
                                  id='c1'
                                  name='radioContact'
                                  checked={selectedradio === "yes"}
                                  onClick={(e) => {
                                    onClick();
                                  }}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleChangeRadio(e);
                                    setFormValues(
                                      JSON.parse(
                                        localStorage.getItem("allValues")
                                      )
                                    );
                                    setSelectedradio(
                                      JSON.parse(
                                        localStorage.getItem("selectedRadio")
                                      )
                                    );
                                    setShowInfos(
                                      JSON.parse(
                                        localStorage.getItem("showInfos")
                                      )
                                    );
                                  }}
                                  // value={formValues.radioContact}
                                  value='yes'
                                  label={t("labelyes.translated-text")}
                                />
                                <Radio
                                  id='c2'
                                  name='radioContact'
                                  checked={selectedradio === "no"}
                                  onClick={(e) => {
                                    onClickNo();
                                    formValues.adressCourrielForm = "";
                                    formValues.telNum = "";
                                  }}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleChangeRadio(e);
                                    setFormValues(
                                      JSON.parse(
                                        localStorage.getItem("allValues")
                                      )
                                    );
                                    setSelectedradio(
                                      JSON.parse(
                                        localStorage.getItem("selectedRadio")
                                      )
                                    );
                                    setShowInfos(
                                      localStorage.setItem(
                                        "showInfos",
                                        JSON.stringify(false)
                                      )
                                    );
                                  }}
                                  //value={formValues.radioContact}
                                  value='no'
                                  label={t("labelno.translated-text")}
                                />
                              </Fieldset>
                            </div>
                            <p>{formErrors.radioContact}</p>
                            <div>
                              {showInfos && (
                                <div>
                                  <div className={styles.inputinfo}>
                                    <FormGroup
                                      label={{
                                        htmlFor: "regular",
                                      }}
                                    >
                                      <Input
                                        id='mail'
                                        name='adressCourrielForm'
                                        value={formValues.adressCourrielForm}
                                        type='courriel'
                                        placeholder={t(
                                          "courriel.translated-text"
                                        )}
                                        onChange={(e) => {
                                          // handleadressCourrielFormChange(e);
                                          handleChange(e);
                                          setFormValues(
                                            JSON.parse(
                                              localStorage.getItem("allValues")
                                            )
                                          );
                                        }}
                                      />
                                    </FormGroup>
                                  </div>
                                  <p>{formErrors.adressCourrielForm}</p>
                                  <div>
                                    <FormGroup
                                      label={{
                                        htmlFor: "regular",
                                      }}
                                    >
                                      <Input
                                        id='tele'
                                        name='telNum'
                                        type='tel'
                                        value={formValues.telNum}
                                        placeholder={t(
                                          "phoneNo.translated-text"
                                        )}
                                        inputMode='numeric'
                                        //mask="999 999-9999"
                                        onChange={(e) => {
                                          // handleTelNumChange(e);
                                          handleChange(e);
                                          setFormValues(
                                            JSON.parse(
                                              localStorage.getItem("allValues")
                                            )
                                          );
                                        }}
                                      />
                                    </FormGroup>
                                  </div>
                                  <p>{formErrors.telNum}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </Routes>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </form>
          </div>
        </div>
        <div
          className={`p-2 pb-3 w-100 d-flex flex-row align-items-start ${styles.footer}`}
        >
          <div>
            <div className={`justify-content-center ${styles.imgcolorsmall}`}>
              <div id='arrow' className={styles.arrowsection}>
                <ArrowLeft
                  size='medium'
                  title='arrow-left'
                  style={{ cursor: "pointer" }}
                  onMouseOver={(e) => {
                    handleChangeNavButton("faderev");
                  }}
                  onClick={(e) => {
                    prevPage(formSecNum);
                  }}
                  value='slide-right'
                />
              </div>
            </div>
          </div>
          <div className='w-75'></div>
          <div className='w-100 d-flex flex-row align-items-end'>
            <div className='w-100' href='#top'>
              {formSecNum == 5 ? (
                <Button
                  name='btnsubmit'
                  type='submit'
                  form='incidentForm'
                  onClick={(e) => {
                    setFormErrors(validate(formValues));

                    e.currentTarget.blur();
                  }}
                  appearance='primary'
                  fluid
                  children={t("btnformsubmit.translated-text")}
                />
              ) : (
                <Button
                  className='dsc-btn-cust'
                  name='btnsubmit'
                  type='input'
                  appearance='primary'
                  fluid
                  onKeyUp={(e) => {
                    e.target.blur();
                  }}
                  onMouseOver={(e) => {
                    handleChangeNavButton("fadeff");
                    e.target.blur();
                  }}
                  onClick={(e) => {
                    setFormErrors(validate(formValues));
                    nextPage();
                    e.target.blur();
                  }}
                  children={
                    formSecNum == 4
                      ? t("btnformsubmit.translated-text")
                      : t("btnformnext.translated-text")
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Screen04;
