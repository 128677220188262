//import './App.css';
import { Header, Accueil, ManageQrCode } from "./components";
import React, { useState, useEffect } from "react";
import "./AppPilot.css";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import axios from "axios";

import AWS from "aws-sdk";
import aws4 from "aws4";
import apiConfig from "./apiConfig.json";
import Amplify, { Auth } from "aws-amplify";

function AppPilot(props) {
  const { t } = useTranslation();
  const userEmail = () =>
    Auth.currentSession()
      .then((response) => response.getIdToken().payload.email)
      .then((data) => {
        return data;
      });
  return (
    <div className='App bg_screen'>
      <Header />
      <Routes>
        <Route exact={true} path='/*' element={<Accueil />} />
        <Route
          path='/protected/'
          element={<PrivateRoute />}
          // element={<PrivateRoute element={<ManageQrCode />} />}
        />
        <Route
          path='/protected/ManageQrCode'
          element={
            <ManageQrCode
              token={userEmail()}
              client={process.env.REACT_APP_CO_USER_POOL_WEB_CLIENT}
            />
          }
        />
      </Routes>
    </div>
  );
}
export default AppPilot;
