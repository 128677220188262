const INITIAL_STATE = {
  CodeQr: "toto",
};

function CodeQRReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADDQRCODE": {
      return {
        ...state,
        //stateContext: [...state.stateContext, action.payload],
        CodeQr: action.payload,
      };
    }
  }
  return state;
}
export default CodeQRReducer;
